import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import contentStyles from './BUnitCardSection/BUnitCardSection.module.sass';
import styles from './BUnitFieldsSections.module.sass';
import { selectNLS } from '@/store/selectors/admin/superset/supersets';
import BUnitSpec from './BUnitSpec/BUnitSpec';
import CreateNewSpecBtn from './CreateNewSpecBtn/CreateNewSpecBtn';
import useCheckPermission from '@/components/App/Admin/hooks/checkPermission';
import specInputStyle from './BUnitSpec/BUnitSpecInput/BUnitSpecInput.module.sass';
import newBUnitContext, { BUnitContextProps } from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitContext';

export type Spec = string[];
export type UpdateSpecFunc = (newSpec: Spec) => void;
interface BUnitSpecializationsProps {
  spec: Spec;
  updateUnitField: UpdateSpecFunc;
}

export default function BUnitSpecializations({ spec, updateUnitField }: BUnitSpecializationsProps) {
  const NLS = useSelector(selectNLS);
  const { id } = useContext(newBUnitContext) as Required<BUnitContextProps>;
  const checkPermission = useCheckPermission();

  const handleUpdateSpecialisation = (newSp: string, index:number) => {
    const newSpecArr = [...spec];
    if (index > newSpecArr.length - 1) newSpecArr.push(newSp);
    newSpecArr[index] = newSp.trim();
    updateUnitField(newSpecArr);
  };

  const generateKey = (index: number): string => {
    let key = `sp${id}`;
    if (spec[index]) key += spec[index];
    key += `${index}`;
    return key;
  };

  const removeSpec = (filterIndex: number) => {
    const filteredSpec = spec.filter((_, i) => i !== filterIndex);
    updateUnitField(filteredSpec);
  };

  const handleCreateSpec = () => {
    updateUnitField([...spec, '']);
  };

  return (
    <>
      <div className={contentStyles['field-title']}>
        {NLS['tree.card.tags']}
      </div>
      <ul className={styles['specializations-list']}>
        {spec.map((sp, index) => (
          checkPermission('modify_units') ? (
            <BUnitSpec
              key={generateKey(index)}
              spec={spec}
              index={index}
              removeSpec={removeSpec}
              updateSpec={handleUpdateSpecialisation}
            />
          ) : <div className={specInputStyle['spec-input']}>sp</div>
        ))}
        {(checkPermission('modify_units')) && (
        <CreateNewSpecBtn
          handleCreateSpec={handleCreateSpec}
        />
        )}
      </ul>
    </>
  );
}