import React, { useContext } from 'react';
import styles from './BUnitBranch.module.sass';
import { UnitId } from '@/store/types/user/units';
import BranchUnitsGroup
  from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/DepartmentUnit/BUnitBranch/BranchUnitsGroup/BranchUnitsGroup';
import useSplitedUnitsByType
  from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/DepartmentUnit/BUnitBranch/useSplitedUnitsByType';
import newBUnitContext from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitContext';
import { useAdminSelector } from '@/store/hooks/adminRedux';
import { selectUnitsTreeData } from '@/store/selectors/admin/superset/unitsTree';
import { isDrawVertical } from '@/components/Common/UnitsTree/unitsTree';
import BUnitChildrenContainer, { BUnitContainerType } from '../../BUnitChildrenContainer/BUnitChildrenContainer';

interface BUnitBranchProps {
  childrenUnits?: UnitId[];
  isOpen: boolean;
}

function BUnitBranch({ childrenUnits, isOpen }: BUnitBranchProps) {
  const { units } = useAdminSelector(selectUnitsTreeData);
  const childrenUnitsByActorType = useSplitedUnitsByType(childrenUnits, units);
  const { id } = useContext(newBUnitContext);

  if (!childrenUnits?.length) return null;

  return (
    <div className={`${isDrawVertical(units, id) && styles.vertical}`}>
      <BUnitChildrenContainer
        isOpen={isOpen}
        type={BUnitContainerType.Branch}
      >
        <div>
          <BranchUnitsGroup isActor={false}>
            {childrenUnitsByActorType[0]}
          </BranchUnitsGroup>
          <BranchUnitsGroup isActor>
            {childrenUnitsByActorType[1]}
          </BranchUnitsGroup>
        </div>
      </BUnitChildrenContainer>
    </div>
  );
}

export default BUnitBranch;