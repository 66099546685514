import React, { useCallback, useMemo } from 'react';
import { useAdminSelector } from '@/store/hooks/adminRedux';
import FileUploadingNotify from './FileUploadingNotify/FileUploadingNotify';
import useUnload from '@/hooks/events';

interface FilesNotificationsProps {
  isHidden: boolean;
  startIndex: number
}
export default function FilesNotifications({
  isHidden,
  startIndex,
}: FilesNotificationsProps) {
  const { uploadFilesProgress } = useAdminSelector((state) => state.uploads);

  const processingFilesListName = useMemo(
    () => Object.keys(uploadFilesProgress).filter((key) => uploadFilesProgress[key] < 100),
    [uploadFilesProgress],
  );

  const unloadMessage = useCallback((e: BeforeUnloadEvent) => {
    if (!processingFilesListName.length) return;
    e.preventDefault();
    e.returnValue = 'Не все файлы были загружены';
  }, [processingFilesListName.length]);

  useUnload(unloadMessage);

  return (
    <>
      {processingFilesListName.map((name, index) => (
        <FileUploadingNotify
          key={`${name}-file-noty`}
          name={name}
          progress={uploadFilesProgress[name]}
          isHidden={isHidden}
          index={startIndex + index}
        />
      ))}
    </>
  );
}