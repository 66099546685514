import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import { selectCurrentLangID } from '@/store/selectors/admin/superset/projects';
import axiosProcessing from '@/axiosConfig';
import { Rating, RatingResult, SelectedParams } from '@/store/types/admin/superset/cuts/fourD';
import { ABSOLUTE_SCORE_TYPE, PERCENTILE_SCORE_TYPE } from '@/components/App/Admin/Results/Cuts/FourD/constants';
import { selectSelectedCriteriaInFormatToBeSent } from '@/store/selectors/admin/superset/process/cuts/cuts';
import { selectSelectedParams } from '@/store/selectors/admin/superset/process/cuts/fourD';
import { AdminState } from '@/store/admin';
import { Criterion } from '@/store/types/admin/superset/cuts/criteria';

interface FormDataResultsByRatings {
  ratingX: string,
  ratingY: string,
  ratingSize: string,
  ratingColor: string,
  criterios?: Criterion[]
}

export interface ResponseRatingResult {
  id: string,
  perc: number,
  score: number,
  color: string,
}

export interface ResponseRatingsResults {
  [key: string]: ResponseRatingResult[]
}

const combinedRatingsResultsData = (
  ratingsResults: ResponseRatingsResults,
  selectedParams: FormDataResultsByRatings,
): RatingResult[] => {
  const {
    ratingX, ratingY, ratingSize, ratingColor,
  } = selectedParams;
  const xData = ratingsResults[ratingX];
  const yData = ratingsResults[ratingY];
  const sizeData = ratingsResults[ratingSize];
  const colorData = ratingsResults[ratingColor];

  return xData.reduce((acc, xItem) => {
    const yItem = yData.find(({ id: yID }) => yID === xItem.id);

    // If there is no data on the y axis
    if (!yItem) {
      return acc;
    }

    const sizeItem = sizeData.find(({ id: sizeID }) => sizeID === xItem.id);
    const colorItem = colorData.find(({ id: colorID }) => colorID === xItem.id);

    const size = sizeItem ? sizeItem.perc : 50;

    acc.push({
      id: xItem.id,
      scores: {
        [PERCENTILE_SCORE_TYPE]: {
          xPerc: xItem.perc,
          yPerc: yItem.perc,
        },
        [ABSOLUTE_SCORE_TYPE]: {
          xPerc: xItem.score,
          yPerc: yItem.score,
        },
      },
      size,
      color: colorItem ? colorItem.color : null,
    });

    return acc;
  }, [])
    // sort from more to less by size.
    .sort((a, b) => b.size - a.size);
};

export const loadRatingsFourD = createAsyncThunk<
Rating[],
void
>(
  'superset/admin/process/cuts/fourD/loadRatingsFourD',
  async (_, { getState, rejectWithValue }) => {
    const state = getState();
    const { supersetId } = selectSuperset(state);
    // @ts-ignore
    const langID = selectCurrentLangID(state);

    try {
      const response = await axiosProcessing.get(
        `/api/admin/read4Dratings/${supersetId}/${langID}`,
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const setSelectedParams = createAction<SelectedParams>(
  'superset/admin/process/cuts/fourD/setSelectedParams',
);

export const getResultsByRatings = createAsyncThunk<
RatingResult[],
void
>(
  'superset/admin/process/cuts/fourD/getResultsByRatings',
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as AdminState;
    const { headers } = state.admin.authentication;
    const { supersetId } = selectSuperset(state);
    const langID = selectCurrentLangID(state);
    const selectedCriteriaForSendingFormat = selectSelectedCriteriaInFormatToBeSent(state);
    const selectedParams = selectSelectedParams(state);

    const formParams: FormDataResultsByRatings = {
      ratingX: selectedParams.xRatingID,
      ratingY: selectedParams.yRatingID,
      ratingSize: selectedParams.ratingSizeID,
      ratingColor: selectedParams.ratingColorID,
      criterios: selectedCriteriaForSendingFormat,
    };

    try {
      const response = await axiosProcessing.post(
        `/api/admin/read4DratingsScores/${supersetId}/${langID}`,
        formParams,
        { headers },
      );

      return combinedRatingsResultsData(response.data, formParams);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const setScoreType = createAction(
  'superset/admin/process/cuts/fourD/setScoreType',
);