import React, { useMemo, useState } from 'react';
import styles from './SelectTargetDepartmentDropdown.module.sass';
import { useAdminSelector } from '@/store/hooks/adminRedux';
import { selectUnitsTreeData } from '@/store/selectors/admin/superset/unitsTree';
import CardSelectDropdown from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardFields/BUnitFieldsContent/BUnitFieldsSections/BUnitSpec/CardSelectDropdown/CardSelectDropdown';
import CardSelectDropdownButton from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardFields/BUnitFieldsContent/BUnitFieldsSections/BUnitSpec/CardSelectDropdown/CardSelectDropdownButton/CardSelectDropdownButton';
import useOutsideClick from '@/hooks/checkOutsideClick';
import SelectTargetDepartmentInput from './SelectTargetDepartmentInput/SelectTargetDepartmentInput';
import { isOneStringEnterAnother } from '@/helpers/helpers';
import { TreeUnits } from '@/store/types/admin/superset/unitsTree';

interface SelectTargetDepartmentDropdownProps {
  selectDepartment: (newParent: string) => void;
  selectedDepartment?: string;
  unitId?: string;
}

export default function SelectTargetDepartmentDropdown(
  { selectDepartment, selectedDepartment, unitId = '' }: SelectTargetDepartmentDropdownProps,
) {
  const [isOpen, toggleOpen] = useState <boolean>(false);
  const { units } = useAdminSelector(selectUnitsTreeData) as TreeUnits;
  // @ts-ignore
  const [inputValue, setInputValue] = useState<string>(selectedDepartment ? units[selectedDepartment].name : '');

  const dropdownRef = useOutsideClick<() => void, HTMLDivElement>(() => toggleOpen(false), isOpen);

  const departmentsList = useMemo(
    () => Object.entries(units).filter(([, unit]) => unit.isunit === 1),
    [units],
  );

  const filteredDepartments = useMemo(() => departmentsList.filter(([id, unit]) => {
    const value = inputValue.trim();
    const depName = unit.name.trim();
    if (!depName.trim()) return false;
    if (id === selectedDepartment) return false;
    return isOneStringEnterAnother(depName, value);
  }), [departmentsList, inputValue, selectedDepartment]);

  return (
    <div
      className={styles['dropdown-container']}
      ref={dropdownRef}
    >
      <SelectTargetDepartmentInput
        inputValue={inputValue}
        id={`${unitId}-card-dep`}
        setInputValue={setInputValue}
        toggleOpen={toggleOpen}
      />
      {isOpen && (
      <CardSelectDropdown>
        {filteredDepartments.map(([id, unit]) => (
          <CardSelectDropdownButton
            key={`sel-dep-${id}`}
            onClick={() => {
              selectDepartment(id);
              setInputValue(unit.name);
              toggleOpen(false);
            }}
          >
            {unit.name}
          </CardSelectDropdownButton>
        ))}
      </CardSelectDropdown>
      )}
    </div>
  );
}