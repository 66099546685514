import React, { Dispatch, SetStateAction } from 'react';
import { useParams } from 'react-router-dom';
import SelectFileModal from '../SelectFileModal';
import { useAdminSelector } from '@/store/hooks/adminRedux';
import { selectWebinarsList } from '@/store/selectors/admin/webinars';
import { CreateWebinarFilesPlaces } from '@/components/App/Webinar/CreateWebinarPage/types/webinarPageTypes';

interface SelectWebinarFilesModalProps {
  isOpen: boolean;
  toggleOpen: Dispatch<SetStateAction<boolean>>
  place: CreateWebinarFilesPlaces;
  selectFile: (src: string) => void;
}

export default function SelectWebinarFilesModal({
  isOpen, toggleOpen, place, selectFile,
}: SelectWebinarFilesModalProps) {
  const { webinarId } = useParams();
  const { webinarsList } = useAdminSelector(selectWebinarsList);
  const files: string[] = webinarId ? webinarsList?.[webinarId]?.files?.[place] : [];

  const handleCloseModal = () => {
    toggleOpen(false);
  };

  if (!webinarId) return null;

  return (
    <SelectFileModal
      isOpen={isOpen}
      closeModal={handleCloseModal}
      filesSrc={files}
      selectFile={selectFile}
    />
  );
}