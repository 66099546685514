import axios from 'axios';
import { useCallback } from 'react';
import { useAdminSelector } from '@/store/hooks/adminRedux';

interface UploadFileProps<T> {
  url: string;
  formData: T,
  onUploadProgress?: ((progressEvent: any) => void) | undefined
}

const useFileUploadWithProgress = () => {
  const { headers } = useAdminSelector((state) => state.admin.authentication);

  const uploadFile = useCallback(async <FormData>({
    url,
    formData,
    onUploadProgress,
  }: UploadFileProps<FormData>) => {
    try {
      const response = await axios.post(
        url,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            ...headers,
          },
          ...(onUploadProgress && { onUploadProgress }),
        },
      );

      return response.data;
    } catch (err) {
      return err;
    }
  }, [headers]);

  return uploadFile;
};

export default useFileUploadWithProgress;