import React from 'react';
import { useAdminSelector } from '@/store/hooks/adminRedux';
import { selectUsersList } from '../selectors/usersList';
import SuperAdminUser from './SuperAdminUser';

export default function UsersList() {
  const usersList = useAdminSelector(selectUsersList);

  if (!Object.keys(usersList).length) return <span>No users</span>;

  return (
    <ul>
      {Object.entries(usersList).map(([id, user]) => (
        <SuperAdminUser
          key={id}
          user={user}
        />
      ))}
    </ul>
  );
}