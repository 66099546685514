import React from 'react';
import { User } from '../../../../types/usersList';
import styles from './SuperAdminUserMainInfo.module.sass';

interface SuperAdminUserMainInfoProps {
  user: User
}

export default function SuperAdminUserMainInfo({ user }: SuperAdminUserMainInfoProps) {
  const { name, email, company } = user;
  return (
    <div className={styles['main-info']}>
      <span className={styles.name}>
        {name || 'Без имени'}
      </span>
      <span className={styles.email}>
        {email}
      </span>
      <span className={styles.email}>
        {company}
      </span>
    </div>
  );
}