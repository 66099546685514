import React, { useEffect, useState } from 'react';
import styles from './PreviewByFileType.module.sass';

interface PreviewByFileTypeProps {
  src: string;
  file?: File
}

export default function PreviewByFileType({ src, file }: PreviewByFileTypeProps) {
  const [prevSrc, setPrevSrc] = useState<string>(src);
  const [type, setType] = useState<string>('');

  useEffect(() => {
    if (prevSrc) {
      if (prevSrc.endsWith('mp4')) {
        setType('video');
      } else {
        setType('image');
      }
      return;
    }

    if (!file) return;

    const reader = new FileReader();

    if (file.type.startsWith('image/')) {
      reader.onload = (e) => setPrevSrc(e.target?.result as string);
      setType('image');
      reader.readAsDataURL(file);
    } else if (file.type.startsWith('video/')) {
      setType('video');
      setPrevSrc(URL.createObjectURL(file)); // Use Object URL for video preview
    }
  }, [file, prevSrc]);

  if (!type) return null;

  switch (type) {
    case 'video': return (
      <video
        className={styles['file-prev']}
        src={prevSrc}
        controls
        preload="metadata"
      >
        <track kind="captions" src="" default />
      </video>
    );
    case 'image': return (
      <img
        className={styles['file-prev']}
        src={prevSrc}
        alt="Preview"
      />
    );
    default: return null;
  }
}