import React, {
  Dispatch,
  SetStateAction,
  useContext, useEffect, useState,
} from 'react';
import BUnitContext, {
  BUnitContextProps,
} from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitContext';
import BUnitCardInput, {
  CardFieldType,
} from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardFields/BUnitFieldsContent/BUnitFieldsSections/BUnitCardSection/BUnitCardInput/BUnitCardInput';
import styles from './BUnitCardFields.module.sass';
import BUnitSpecializations from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardFields/BUnitFieldsContent/BUnitFieldsSections/BUnitSpecializations';
import { useAdminDispatch, useAdminSelector } from '@/store/hooks/adminRedux';
import BUnitCardFooterButtons
  from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardFooter/BUnitCardFooterButtons';
import useBUnitCardForm, { BUnitCardFormFields, BUnitCardFormFieldsKeys } from './useBUnitCardForm';
import { updateTreeUnits } from '@/store/actions/admin/superset/unitsTree';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import SaveChangesModal
  from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/SaveChangesModal/SaveChangesModal';
import { isArraysEqual } from '@/helpers/helpers';
import UnitCardContext, {
  BUnitCardContextProps,
} from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/bUnitCardContext';
import SelectTargetDepartment from '@/components/App/Admin/BUnitsSection/CreateBUnitSection/CreateBUnitModal/CreateBUnitForm/SelectTargetDepartment/SelectTargetDepartment';

interface BUnitCardFieldsProps {
  isUnitUpdated: boolean;
  toggleUpdated: Dispatch<SetStateAction<boolean>>;
}

interface UnitUpdateData {
  name?: string;
  jobTitle?: string;
  email?: string;
  spec?: string[];
  parent?: string;
}

function BUnitCardFields({
  isUnitUpdated,
  toggleUpdated,
}: BUnitCardFieldsProps) {
  const { unit, id } = useContext(BUnitContext) as Required<BUnitContextProps>;
  const { supersetId } = useAdminSelector(selectSuperset);
  const [specs, setSpecs] = useState<string[]>([...unit.spec]);
  const dispatch = useAdminDispatch();
  const { handleButtonClickInModal } = useContext(UnitCardContext) as Required<BUnitCardContextProps>;
  const [department, setDepartment] = useState<string | undefined>(unit.parent || '');

  const handleChangeDepartment = (departmentId: string) => {
    setDepartment(departmentId);
  };

  const {
    register, handleSubmit, formState, reset,
  } = useBUnitCardForm();

  const onHandleSubmit = (data: BUnitCardFormFields) => {
    // @ts-ignore
    const updatedFields: UnitUpdateData = Object.keys(formState.dirtyFields)
    // @ts-ignore
      .reduce((acc: UnitUpdateData, key: BUnitCardFormFieldsKeys) => ({
        ...acc,
        [key]: data[key as BUnitCardFormFieldsKeys]?.trim(),
      }), {});

    if (!isArraysEqual(unit.spec, specs)) updatedFields.spec = specs;

    dispatch(updateTreeUnits({
      unitsData: [{
        ID: id,
        newParentId: department !== unit.parent ? department : undefined,
        ...updatedFields,
      }],
      supersetId,
    })).then(() => {
      const newFormValues: BUnitCardFormFields = Object.entries(data).reduce((acc, [key, value]) => {
        // @ts-ignore
        acc[key] = typeof value === 'string' ? value.trim() : value;

        return acc;
      }, {});
      reset(newFormValues);
    })
      .catch(() => null);

    handleButtonClickInModal();
  };

  const formId = `${id}-card-unit-field`;

  useEffect(() => {
    toggleUpdated(formState.isDirty || !isArraysEqual(specs, unit.spec) || department !== unit.parent);
  }, [department, formState.isDirty, specs, toggleUpdated, unit.parent, unit.spec]);

  const handleReset = () => {
    setSpecs(unit.spec);
    setDepartment(unit.parent || '');
    reset();
    handleButtonClickInModal();
  };

  const isConfirmActive = () => {
    if (!isUnitUpdated) return false;
    return Object.keys(formState.errors).length <= 0;
  };

  return (
    <>
      <SaveChangesModal>
        <BUnitCardFooterButtons
          isUnitUpdated={isConfirmActive()}
          reset={handleReset}
          formId={formId}
        />
      </SaveChangesModal>
      <form
        className={styles['b-unit-card-form']}
        onSubmit={handleSubmit(onHandleSubmit)}
        id={formId}
        noValidate
      >
        {Object.values(CardFieldType).map((type: CardFieldType) => (
          <BUnitCardInput
            key={`${id}-card-${type}-input`}
            register={register}
            cardFieldType={type}
            defaultValue={unit[type]}
            unitId={id}
            inputError={formState.errors[type]?.message}
          />
        ))}
        <SelectTargetDepartment
          currentDepartment={department}
          selectDepartment={handleChangeDepartment}
        />
        <BUnitSpecializations
          spec={specs}
          updateUnitField={setSpecs}
        />
        <BUnitCardFooterButtons
          isUnitUpdated={isConfirmActive()}
          reset={handleReset}
          formId={formId}
        />
      </form>
    </>
  );
}

export default BUnitCardFields;