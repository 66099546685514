import React, {
  useState,
} from 'react';
import { useSpring, a } from '@react-spring/web';
import { withErrorBoundary } from 'react-error-boundary';
import styles from './NotificationContainer.module.sass';
import AdminNotification from '@/components/Common/NotificationsBar/AdminNotification/AdminNotification';
import useNotificationTop from '@/hooks/notificationTop';

interface NotificationAnimatedContainerProps {
  notify: NotifyType;
  isHidden: boolean;
  index: number;
  withAnimation: boolean;
  withCloseBtn?: boolean;
}
function NotificationAnimatedContainer({
  notify, isHidden, index, withAnimation, withCloseBtn = true,
}: NotificationAnimatedContainerProps) {
  const [ref, calculateTop] = useNotificationTop<HTMLDivElement>(index, isHidden);

  const [topCoordinate, setTopCoordinate] = useState<string>(calculateTop());

  const animationProps = useSpring({
    from: {
      top: topCoordinate,
      opacity: isHidden && index > 2 ? '1' : '0',
    },
    to: {
      top: calculateTop(),
      opacity: isHidden && index > 2 ? '0' : '1',
    },
    onRest: () => {
      setTopCoordinate(calculateTop());
    },
  });

  return (
    <a.div
      className={`${styles['notify-container']} ${isHidden && styles.hidden}`}
      ref={ref}
      style={{
        ...animationProps,
        zIndex: `${100000 - index}`,
      }}
    >
      <AdminNotification
        notify={notify}
        isHidden={isHidden}
        withAnimation={withAnimation}
        index={index}
        withCloseBtn={withCloseBtn}
      />
    </a.div>
  );
}

export default withErrorBoundary(NotificationAnimatedContainer, {
  fallback: null,
});