import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosProcessing from '@/axiosConfig';
import { AdminState } from '@/store/admin';
import { EditWebinarArgs } from '@/components/App/Webinar/CreateWebinarPage/types/webinarPageTypes';
import { WebinarsList } from '@/store/types/admin/webinars';

export const getWebinarsList = createAsyncThunk(
  'admin/super/webinar',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosProcessing.get<WebinarsList>('/api/admin/super/webinars');

      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const editWebinar = createAsyncThunk(
  'admin/super/createWebinar',
  async (data: EditWebinarArgs, { rejectWithValue, getState }) => {
    const state = getState() as AdminState;
    const { webinarFields } = data;
    const { headers } = state.admin.authentication;
    try {
      await axiosProcessing.post('/api/admin/super/webinar/store', webinarFields, { headers });

      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);