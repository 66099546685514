/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';
import styles from './SelectTargetDepartment.module.sass';
import SelectTargetDepartmentDropdown from './SelectTargetDepartmentDropdown/SelectTargetDepartmentDropdown';
import useNLS from '@/components/App/Admin/hooks/hooks';

interface SelectTargetDepartmentProps {
  selectDepartment: (id: string) => void;
  currentDepartment?: string;
  unitId?: string;
}

export default function SelectTargetDepartment({
  selectDepartment, currentDepartment, unitId = '',
}: SelectTargetDepartmentProps) {
  const tNLS = useNLS();
  const handleSelectDepartment = (newDepartmentId: string): void => {
    selectDepartment(newDepartmentId);
  };

  return (
    <>
      {/* eslint-disable-next-line */}
      <label
        className={styles.title}
        htmlFor={`${unitId}-card-dep`}
      >
        {tNLS('tree.card.dep')}
      </label>
      <SelectTargetDepartmentDropdown
        selectedDepartment={currentDepartment}
        selectDepartment={handleSelectDepartment}
      />
    </>
  );
}