// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".main-container--S-9mT.animated--buf4A{overflow-y:hidden;overflow-x:hidden}", "",{"version":3,"sources":["webpack://./src/components/Common/AnimatedContainerByHeight/AnimatedContainerByHeight.module.sass"],"names":[],"mappings":"AACE,uCACE,iBAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main-container": "main-container--S-9mT",
	"animated": "animated--buf4A"
};
export default ___CSS_LOADER_EXPORT___;
