import {
  combineReducers, configureStore, Reducer, Store, AnyAction,
} from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import adminReducers from './reducers/admin';
import notifyReducer from '@/store/reducers/notify';
import errorsReducer from '@/store/reducers/errors';
import uploadsReducer from '@/store/reducers/uploads';
import saveLangIDMiddleware from '@/store/middleware/admin/Langs/setLanguage';

const staticReducers = {
  admin: adminReducers,
  notify: notifyReducer,
  error: errorsReducer,
  uploads: uploadsReducer,
};

const createReducer = (asyncReducers: Record<string, Reducer>) => combineReducers({
  ...staticReducers,
  ...asyncReducers,
});

interface ExtendedStore extends Store {
  asyncReducers: Record<string, Reducer>;
}

const adminStore = configureStore({
  reducer: staticReducers,
  devTools: true,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(saveLangIDMiddleware),
}) as ExtendedStore;

adminStore.asyncReducers = {};

export const injectReducer = (key: string, asyncReducer: Reducer) => {
  if (!adminStore.asyncReducers[key]) {
    adminStore.asyncReducers[key] = asyncReducer;
    adminStore.replaceReducer(createReducer(adminStore.asyncReducers));
  }
};

export type AdminDispatch = (thunkAction: ThunkAction<void, any, unknown, AnyAction>) => Promise<any> &
  typeof adminStore.dispatch;

export type AdminState = ReturnType<typeof adminStore.getState>;

export default adminStore;