import React from 'react';
import styles from './FileForUploadCover.module.sass';
import CircleProgress from './CircleProgress/CircleProgress';

interface FileForUploadCoverProps {
  fileProgress: number;
  removeFile?: () => void;
}

export default function FileForUploadCover({ fileProgress, removeFile }: FileForUploadCoverProps) {
  if (fileProgress >= 100) return null;

  return (
    <div
      className={styles['file-cover']}
    >
      <CircleProgress
        percentage={fileProgress || 0}
      />
      {removeFile && (
      <button
        className={styles['remove-button']}
        onClick={removeFile}
        type="button"
      >
        {' '}
        <span className={`${styles['remove-icon']} icon-remove-light`} />
      </button>
      )}
    </div>
  );
}