import React, { useCallback, useEffect, useState } from 'react';
import styles from './SvgCircle.module.sass';
import { getClearClassNames } from '@/helpers/helpers';

interface SvgCircleProps {
  percentage?: number;
  type: SvgCircleType
}

export enum SvgCircleType {
  Background = 'background',
  Fill = 'fill',
}

export default function Circle({ percentage, type }: SvgCircleProps) {
  const r = 70;
  const circ = 2 * Math.PI * r;

  const calcStrokeDashoffset = useCallback(() => {
    if (type === SvgCircleType.Background) return 0;
    const pct = percentage || 0;
    return circ - circ * (pct / 100);
  }, [circ, percentage, type]);

  const [pct, setPct] = useState<number>(calcStrokeDashoffset());

  useEffect(() => {
    setPct(calcStrokeDashoffset());
  }, [calcStrokeDashoffset]);

  return (
    <circle
      className={getClearClassNames([
        styles['svg-circle'],
        styles[type],
      ])}
      r={r}
      cx={100}
      cy={100}
      strokeDasharray={circ}
      strokeDashoffset={pct}
    />
  );
}