import React from 'react';
import styles from './FilesForUploadList.module.sass';
import FileForUpload from './FileForUpload/FileForUpload';
import { useAdminSelector } from '@/store/hooks/adminRedux';
import { getClearClassNames } from '@/helpers/helpers';

interface FilesForUploadListProps {
  uploadedFilesSrc?: string[];
  newFiles?: File[];
  fileHandleClick?: (fileSrc: string) => void;
  deleteNewFile?: (fileName: string) => void,
  getFileKeyInUploads?: (fileName: string) => string;
}

export default function FilesGallerey({
  uploadedFilesSrc = [],
  newFiles = [],
  fileHandleClick,
  deleteNewFile = () => {},
  getFileKeyInUploads = (fileName) => fileName,
}: FilesForUploadListProps) {
  const filesProgress = useAdminSelector((state) => state.uploads.uploadFilesProgress);

  return (
    <ul className={getClearClassNames([
      styles['files-list'],
    ])}
    >
      {uploadedFilesSrc.map((file) => (
        <FileForUpload
          src={file}
          fileHandleClick={fileHandleClick}
          fileProgress={100}
        />
      ))}
      {newFiles.map((file) => (
        <FileForUpload
          file={file}
          fileProgress={filesProgress[getFileKeyInUploads(file.name)] || 0}
          deleteFileFromList={() => {
            deleteNewFile(file.name);
          }}
        />
      ))}
    </ul>
  );
}