import React, {
  useCallback, useMemo, useRef, useState,
} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Импортируем стили
import { UseFormSetValue, UseFormWatch } from 'react-hook-form';
import styles from './CreateWebinarTextArea.module.sass';
import { getClearClassNames } from '@/helpers/helpers';
import { CreateWebinarFieldsType, CreateWebinarFielsNamesEnum, CreateWebinarFilesPlaces } from '../../../../types/webinarPageTypes';
import SelectWebinarFilesModal from './SelectWebinarFilesModal/SelectWebinarFilesModal';

interface CreateWebinarTextareaProps {
  name: CreateWebinarFielsNamesEnum;
  setValue: UseFormSetValue<CreateWebinarFieldsType>;
  watch: UseFormWatch<CreateWebinarFieldsType>;
}

function CreateWebinarTextarea({
  name, setValue, watch,
}: CreateWebinarTextareaProps) {
  const [isModalOpen, toggleModal] = useState<boolean>(false);
  const quillRef = useRef<ReactQuill | null>(null);

  const editorHtml = watch(name) || '';

  const handleChange = (html: string) => {
    setValue(name, html);
  };

  const handleOpenModal = useCallback(() => {
    toggleModal(true);
  }, []);

  const selectFile = useCallback((fileSrc: string) => {
    if (!quillRef.current) return;
    const editor = quillRef.current.getEditor();
    const range = editor.getSelection();
    if (!range) return;
    editor.insertEmbed(range.index, 'image', fileSrc);
    toggleModal(false);
  }, []);
  // мемоизация, так как без нее редактор багует
  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ header: [2, 3, 4, 5, false] }],
        ['bold', 'italic', 'underline'],
        ['link'],
        ['image'],
        [{ list: 'ordered' }, { list: 'bullet' }],
      ],
      handlers: {
        image: handleOpenModal,
      },
    },
  }), [handleOpenModal]);

  return (
    <div className={styles['text-area']}>
      <ReactQuill
        ref={quillRef}
        id={`${name}-webinar`}
        value={editorHtml as string}
        onChange={handleChange}
        modules={modules}
        placeholder="Введите текст..."
        className={`${getClearClassNames([styles['text-editor'], styles[name]])}`}
      />
      <SelectWebinarFilesModal
        isOpen={isModalOpen}
        toggleOpen={toggleModal}
        place={CreateWebinarFilesPlaces.Banner}
        selectFile={selectFile}
      />
    </div>
  );
}

export default CreateWebinarTextarea;