export const animateScrollTo = (ref, duration = 2000) => {
  const initPosition = window.scrollY;
  const { requestAnimationFrame, cancelAnimationFrame } = window;
  // maximum amount of pixels we can scroll
  const maxAvailableScroll = document.documentElement.scrollHeight
    - document.documentElement.clientHeight;
  const amountOfPixelsToScroll = initPosition - ref.current.offsetTop;

  let start = null;
  let animationFrame = null;

  const easeOutQuart = (x) => 1 - (1 - x) ** 4;

  const step = (timestamp) => {
    if (!start) {
      start = timestamp;
    }

    const elapsed = timestamp - start;
    const relativeProgress = elapsed / duration;

    const easedProgress = easeOutQuart(relativeProgress);
    const position = initPosition - amountOfPixelsToScroll * Math.min(easedProgress, 1);

    window.scrollTo(0, position);

    if (
      initPosition !== maxAvailableScroll
      && window.scrollY === maxAvailableScroll
    ) {
      cancelAnimationFrame(animationFrame);
      return false;
    }

    if (elapsed < duration) {
      animationFrame = requestAnimationFrame(step);
    }

    return false;
  };

  animationFrame = requestAnimationFrame(step);
};

export const scrollToUpperBorder = (
  targetElement,
  duration = 500,
  offset = 50,
) => {
  // Helper function to get absolute position of the element
  const getTopPosition = (elem) => {
    let location = 0;
    if (elem.offsetParent) {
      do {
        location += elem.offsetTop;
        elem = elem.offsetParent;
      } while (elem);
    }
    return location >= 0 ? location : 0;
  };

  const exactTargetPosition = getTopPosition(targetElement) - offset;
  const startPosition = window.pageYOffset;
  const distance = exactTargetPosition - startPosition;
  const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

  const easeInOutCubic = (time, start, dist, dur) => {
    time /= dur / 2;
    if (time < 1) return (dist / 2) * time * time * time + start;
    time -= 2;
    return (dist / 2) * (time * time * time + 2) + start;
  };

  const animation = (currentTime) => {
    const timeElapsed = currentTime - startTime;
    const run = easeInOutCubic(timeElapsed, startPosition, distance, duration);
    window.scrollTo(0, run);
    if (timeElapsed < duration) requestAnimationFrame(animation);
  };
  requestAnimationFrame(animation);
};

export const innerAnimateScrollTo = (
  parentNode,
  targetNode,
  duration = 2000,
) => {
  const initPosition = parentNode.scrollTop;
  const { requestAnimationFrame, cancelAnimationFrame } = window;
  // maximum amount of pixels we can scroll
  const maxAvailableScroll = parentNode.scrollHeight - parentNode.clientHeight;
  const amountOfPixelsToScroll = initPosition - targetNode.offsetTop;

  let start = null;
  let animationFrame = null;

  const easeOutQuart = (x) => 1 - (1 - x) ** 4;

  const step = (timestamp) => {
    if (!start) {
      start = timestamp;
    }

    const elapsed = timestamp - start;
    const relativeProgress = elapsed / duration;

    const easedProgress = easeOutQuart(relativeProgress);
    parentNode.scrollTop = initPosition - amountOfPixelsToScroll * Math.min(easedProgress, 1);
    if (
      initPosition !== maxAvailableScroll
      && parentNode.scrollY === maxAvailableScroll
    ) {
      cancelAnimationFrame(animationFrame);
      return false;
    }

    if (elapsed < duration) {
      animationFrame = requestAnimationFrame(step);
    }

    return false;
  };

  animationFrame = requestAnimationFrame(step);
};

export const styleTypesToModuleClassNamesStr = (styleTypes, moduleStyles) => styleTypes
  .split(' ')
  .map((strClassName) => moduleStyles[strClassName])
  .join(' ');

export const getClearClassNames = (classNames) => classNames.filter(Boolean).join(' ');

export const getCls = (cls) => cls.filter(([, cond]) => Boolean(cond)).map(([className]) => className);

export const copyStrToClipboard = (str, callback = () => null) => {
  navigator.clipboard.writeText(str).then(callback);
};

export const projectLang = (actor) => {
  if (actor && actor.profile && actor.profile.langId !== '') {
    return actor.profile.langId;
  }
  return 'en';
};

export const formatDate = (date, format) => {
  const map = {
    mm: `0${date.getMonth() + 1}`.slice(-2),
    dd: `0${date.getDate()}`.slice(-2),
    yyyy: date.getFullYear(),
    time: date.toLocaleTimeString(),
  };

  return format.replace(/mm|dd|yyyy|time/gi, (matched) => map[matched]);
};

export const removeTags = (text, tagsToRemove = ['hide']) => {
  let newText = text;
  tagsToRemove.forEach((tagToRemove) => {
    if (newText.includes(tagToRemove)) {
      const openTag = `<${tagToRemove}>`;
      const closeTag = `</${tagToRemove}>`;
      newText = newText.split(openTag).join('').split(closeTag).join('');
    }
  });
  return newText;
};

export const removeTextInsideTags = (text, tags = ['hide']) => {
  if (!text) return '';
  let newText = text;
  tags.forEach((tag) => {
    const openTag = `<${tag}>`;
    const closeTag = `${openTag.slice(0, 1)}/${openTag.slice(1)}`;
    while (newText.includes(tag)) {
      const startPos = newText.indexOf(openTag);
      const endPos = newText.indexOf(closeTag) + closeTag.length;
      newText = `${newText.slice(0, startPos)}${newText.slice(endPos)}`;
    }
  });
  return newText;
};

export const getProjectLink = (webId = '', demo = false) => {
  let linkParam = 'go';
  if (demo) linkParam = 'godemo';
  return `${window.location.origin}/${linkParam}/${webId}`.replaceAll(' ', '');
};

export const makeStrToFormat = (str) => (str ? str.replace(/[\s-]/g, '').toLowerCase() : undefined);

export const isOneStringEnterAnother = (str1, str2) => {
  if (!str1) return false;
  if (!str2) return true;
  const formattedStr1 = makeStrToFormat(str1);
  const formattedStr2 = makeStrToFormat(str2);
  return formattedStr1?.includes(formattedStr2);
};

export const makeUnitNameToFormat = (unitName) => unitName.replace(/^\d+\./, '');

export const sortUnitsByNumber = (unitA, unitB, actorId = '') => {
  if (unitA.id === actorId) return -1;
  if (unitB.id === actorId) return 1;
  const nameA = unitA.name.toLowerCase();
  const nameB = unitB.name.toLowerCase();
  const numA = parseInt(nameA, 10);
  const numB = parseInt(nameB, 10);
  if (!Number.isNaN(numA) && !Number.isNaN(numB)) {
    return numA - numB;
  }
  return nameA.localeCompare(nameB, undefined, { numeric: true });
};

export const getDateInPostgreFormat = (date = undefined) => {
  const currentDate = date ? new Date(date) : new Date();
  return formatDate(currentDate, 'yyyy-mm-dd time');
};

export const getChangedProtocolsAfterSending = (
  relations,
  protocols,
  sentProtocols,
) => {
  const changedProtocols = {};
  const changedRelations = {};
  for (let i = 0; i < sentProtocols.length; i += 1) {
    const protocol = sentProtocols[i];
    if (!protocol.unitId) {
      const { itemId, answer } = protocol;
      if (protocols[itemId] !== answer) {
        changedProtocols[itemId] = protocols[itemId];
      }
    } else {
      const { unitId, itemId, answer } = protocol;
      if (relations[itemId] && relations[itemId][unitId] !== answer) {
        if (!changedRelations[itemId]) {
          changedRelations[itemId] = {};
        }
        changedRelations[itemId][unitId] = relations[itemId][unitId];
      }
    }
  }

  return { changedProtocols, changedRelations };
};

export const setAnswerInLocalStorage = ({
  id,
  updatedItems,
  updatedRelations,
  protocolsTime,
  localKey,
}) => {
  if (id === 'return-code') return;
  const relationsForLocal = {};
  Object.keys(updatedRelations).forEach((itemId) => {
    const itemRelations = updatedRelations[itemId];
    if (!itemRelations) return;
    if (Object.keys(itemRelations).length === 0) return;
    relationsForLocal[itemId] = itemRelations;
  });

  localStorage.setItem(
    localKey,
    JSON.stringify({
      protocols: updatedItems,
      relations: relationsForLocal,
      protocolsTime,
    }),
  );
};

export const getTimeDifferenceInSeconds = ({ time, timezoneOffset }) => {
  const formatTime = new Date(time);
  const now = new Date();

  const offsetDifference = timezoneOffset
    ? timezoneOffset - now.getTimezoneOffset()
    : 0;

  const timeWithOffset = new Date(
    formatTime.getTime() - offsetDifference * 60000,
  );

  return Math.abs((now - timeWithOffset) / 1000);
};

export const getAnswersFromLocalStorage = ({ localKey, savedProtocols }) => {
  if (!localKey) return null;
  const localAnswers = JSON.parse(
    localStorage.getItem(localKey) || localStorage.getItem('unsaved-answers'),
  );

  if (!localAnswers) return { protocols: {}, relations: {}, protocolsTime: {} };

  if (!savedProtocols) return localAnswers;
  const { protocols, relations, protocolsTime } = localAnswers;

  savedProtocols.forEach((itemAnswer) => {
    const {
      unitId, itemId, time, answer,
    } = itemAnswer;
    if (unitId) {
      if (!relations[itemId]?.[unitId]) return;
      if (!protocolsTime[unitId]?.[itemId]) return;
      if (
        getTimeDifferenceInSeconds({ time: protocolsTime[unitId][itemId] })
          + Number(time)
        > 1
      ) {
        delete protocolsTime[unitId][itemId];
        return;
      }
      if (relations[itemId][unitId] === answer) return;
      relations[itemId][unitId] = answer;
    } else {
      if (!protocolsTime[itemId]) return;
      if (
        getTimeDifferenceInSeconds({ time: protocolsTime[itemId] })
          + Number(time)
        > 1
      ) {
        delete protocolsTime[itemId];
        return;
      }
      protocols[itemId] = answer;
    }
  });

  return localAnswers;
};

export const getDateInFormat = (
  defaultDate,
  daysFromUploadForTime = undefined,
  withYear = false,
) => {
  const dateInFormat = new Date(defaultDate);
  const upDay = dateInFormat.getDate().toString().padStart(2, '0');
  const upMonth = (dateInFormat.getMonth() + 1).toString().padStart(2, '0');
  const upYear = dateInFormat.getFullYear();

  const currentDateMs = Date.now();
  const uploadingDateMs = Date.parse(defaultDate);
  const daysFromUpload = Math.trunc(
    (currentDateMs - uploadingDateMs) / (1000 * 3600 * 24),
  );

  let date = `${upDay}.${upMonth}`;
  date += withYear ? `.${upYear}` : '';

  if (daysFromUpload && daysFromUpload > daysFromUploadForTime) {
    return date;
  }

  const upHours = dateInFormat.getHours().toString().padStart(2, '0');
  const upMinutes = dateInFormat.getMinutes().toString().padStart(2, '0');
  const time = `${upHours}:${upMinutes}`;
  return `${time} ${date}`;
};

export const isUnitCanBeSelected = (actorId, unitId, self) => {
  if (!actorId || !unitId) return true;
  if (actorId !== unitId) return true;
  if (self === 1) return true;
  if (!self && self !== 0) return true;
  return !actorId === unitId;
};

export const validateEmail = (email) => {
  // eslint-disable-next-line
  const regex =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return regex.test(email);
};

export const interpolateColors = (color1, color2, position) => {
  const interpolateComponent = (comp1, comp2) => {
    const comp = Math.round(
      parseInt(comp1, 16)
        + (parseInt(comp2, 16) - parseInt(comp1, 16)) * position,
    );
    return comp.toString(16).padStart(2, '0');
  };

  // Разбиваем цвета на компоненты (R, G, B)
  const r1 = color1.substring(1, 3);
  const g1 = color1.substring(3, 5);
  const b1 = color1.substring(5, 7);

  const r2 = color2.substring(1, 3);
  const g2 = color2.substring(3, 5);
  const b2 = color2.substring(5, 7);

  // Интерполируем каждый компонент цвета
  const interpolatedR = interpolateComponent(r1, r2);
  const interpolatedG = interpolateComponent(g1, g2);
  const interpolatedB = interpolateComponent(b1, b2);

  // Собираем и возвращаем интерполированный цвет
  return `#${interpolatedR}${interpolatedG}${interpolatedB}`;
};

export const getColorFromGradient = (colors, percent) => {
  if (percent >= 100) return colors[colors.length - 1];
  if (percent <= 0) return colors[0];
  const colorPosition = ((colors.length - 1) * percent) / 100;
  if (Number.isInteger(colorPosition)) return colors[colorPosition];

  const lowerIndex = Math.floor(colorPosition);
  const upperIndex = Math.ceil(colorPosition);

  const lowerColor = colors[lowerIndex];
  const upperColor = colors[upperIndex];

  const positionWithinInterval = colorPosition - lowerIndex;

  // Производим интерполяцию между lowerColor и upperColor
  return interpolateColors(lowerColor, upperColor, positionWithinInterval);
};

export const getLastKeyWord = (key) => {
  const words = key.split('.');
  return words[words.length - 1];
};

export const resizeInput = (input, offset = 0) => {
  if (!input) return;
  input.style.width = '0';
  input.style.width = `${input.scrollWidth + offset}px`;
};
export const humanFileSize = (bytes, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }
  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;
  do {
    bytes /= thresh;
    u += 1;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh
    && u < units.length - 1
  );
  return `${bytes.toFixed(dp)} ${units[u]}`;
};

export const isArraysEqual = (arr1, arr2) => {
  if (arr1?.length !== arr2?.length) return false;

  for (let i = 0; i < arr1.length; i += 1) {
    if (arr1[i] !== arr2[i]) return false;
  }

  return true;
};

export const mskToInputFormat = (mskDateStr) => {
  const [date, time] = mskDateStr.split(' ');
  const [day, month, year] = date.split('.'); // Извлекаем день, месяц и год
  const [hours, minutes, seconds] = time.split(':'); // Извлекаем часы, минуты и секунды

  const mskDate = new Date(
    Date.UTC(year, month - 1, day, hours - 3, minutes, seconds),
  ); // Учитываем смещение

  const mskOffset = 3 * 60;
  const localOffset = mskDate.getTimezoneOffset();
  const offsetDifference = mskOffset + localOffset;

  const localDate = new Date(mskDate.getTime() + offsetDifference * 60000);

  return localDate.toISOString().slice(0, 16);
};

export const inputToMskFormat = (dateTimeStr) => {
  const localDate = new Date(dateTimeStr);

  const mskOffset = 3 * 60;
  const localOffset = localDate.getTimezoneOffset();
  const offsetDifference = mskOffset + localOffset;

  const mskDate = new Date(localDate.getTime() - offsetDifference * 60000);

  return mskDate;
};

export const makeFileLinkAndDownload = ({ blob, fileName }) => {
  if (blob.size < 1000) return;
  const url = URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const scrollWrapperToStart = () => {
  window.scroll({ top: 0 });
};

export const sortListByTime = (dateA, dateB, order = 'asc') => {
  const timeA = new Date(dateA).getTime();
  const timeB = new Date(dateB).getTime();
  switch (order) {
    case 'asc':
      return timeA - timeB;
    case 'desc':
      return timeB - timeA;
    default:
      throw new Error(`Invalid order: ${order}. Use 'asc' or 'desc'.`);
  }
};

export const createPathByParentId = (departmentId, units) => {
  if (!departmentId) return [];
  const path = [];
  let parent = departmentId;

  while (parent) {
    const parentUnit = units[parent];
    path.unshift(parentUnit.name);
    parent = parentUnit.parent;
  }

  return path;
};

export const selectTargetDepartmentWithPath = (id, units) => {
  if (!id) return undefined;
  return {
    id,
    path: createPathByParentId(id, units),
  };
};