import React, { useEffect } from 'react';
import NotificationAnimatedContainer from '../../NotificationContainer/NotificationAnimatedContainer';
import { useAdminDispatch } from '@/store/hooks/adminRedux';
import { uploadsSlise } from '@/store/reducers/uploads';

interface FileUploadingNotifyProps {
  name: string;
  progress: number
  isHidden: boolean;
  index: number;
}

export default function FileUploadingNotify({
  name, progress, isHidden, index,
}: FileUploadingNotifyProps) {
  const dispatch = useAdminDispatch();
  const notify: NotifyType = {
    id: `${name}-file-notify`,
    message: `${progress}% ${name}`,
    type: 'info',
    lifeTime: 1000000,
  };

  useEffect(() => {
    if (progress === 100) dispatch(uploadsSlise.actions.deleteFileFromUploads(name));
  }, [dispatch, name, progress]);

  return (
    <NotificationAnimatedContainer
      notify={notify}
      index={index}
      isHidden={isHidden}
      withAnimation={false}
      withCloseBtn={false}
    />
  );
}