import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { editWebinar, getWebinarsList } from '@/store/actions/admin/webinar';
import { CreateWebinarFielsNamesEnum, EditWebinarArgs } from '../types/webinarPageTypes';
import LoadingStatus from '@/constants/enums';
import { WebinarsInitialState, AddFileToWebinarArgs, WebinarsList } from '@/store/types/admin/webinars';

const initialState: WebinarsInitialState = {
  webinarsList: {},
  loadingWebinarsStatus: LoadingStatus.Idle,
};

export const webinarsSlise = createSlice({
  name: 'admin/super/webinars',
  initialState,
  reducers: {
    addFileToWebinar: (state, { payload }: PayloadAction<AddFileToWebinarArgs>) => {
      const { webinarId, path, place } = payload;
      if (!(place in state.webinarsList[webinarId].files)) state.webinarsList[webinarId].files[place] = [];
      state.webinarsList[webinarId].files[place] = [...state.webinarsList[webinarId].files[place], path];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWebinarsList.pending, (state) => {
        state.loadingWebinarsStatus = LoadingStatus.Loading;
      })
      .addCase(getWebinarsList.fulfilled, (state, { payload }: PayloadAction<WebinarsList>) => {
        state.webinarsList = payload;
        state.loadingWebinarsStatus = LoadingStatus.Received;
      })
      .addCase(getWebinarsList.rejected, (state) => {
        state.loadingWebinarsStatus = LoadingStatus.Error;
      })
      .addCase(editWebinar.fulfilled, (state, { payload }: PayloadAction<EditWebinarArgs>) => {
        const { oldWebinarId, webinarFields } = payload;
        const webinarId = webinarFields[CreateWebinarFielsNamesEnum.WebinarKey];
        if (oldWebinarId) {
          state.webinarsList[webinarId] = { ...state.webinarsList[webinarId], ...webinarFields };
          if (oldWebinarId !== webinarId) delete state.webinarsList[oldWebinarId];
        } else {
          // @ts-ignore
          state.webinarsList[webinarId] = webinarFields;
        }
      });
  },
});

const { reducer } = webinarsSlise;

export default reducer;