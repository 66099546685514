import React from 'react';
import styles from './FileForUpload.module.sass';
import { getClearClassNames } from '@/helpers/helpers';
import FileForUploadCover from './FileForUploadCover/FileForUploadCover';
import PreviewByFileType from './FileForUploadCover/PreviewByFileType/PreviewByFileType';

interface FileForUploadProps {
  src?: string;
  file?: File;
  fileProgress: number;
  fileHandleClick?: (fileSrc: string) => void;
  deleteFileFromList?: () => void;
}

export default function FileForUpload({
  src = '', file, fileProgress, deleteFileFromList, fileHandleClick = () => {},
}: FileForUploadProps) {
  return (
    <button
      type="button"
      onClick={() => {
        fileHandleClick(src);
      }}
      className={getClearClassNames([
        styles['file-prev-container'],
        fileProgress < 100 && styles.cover,
      ])}
    >
      <FileForUploadCover
        fileProgress={fileProgress}
        removeFile={deleteFileFromList}
      />
      <PreviewByFileType
        src={src}
        file={file}
      />
    </button>
  );
}