import React, { useEffect } from 'react';
import { useAdminDispatch, useAdminSelector } from '@/store/hooks/adminRedux';
import { getUsersList } from './actions/usersList';
import { selectUsersListLoadingStatus } from './selectors/usersList';
import LoadingStatus from '@/constants/enums';
import Preloader from '../../SupersetSettings/Preloader';
import UsersList from './UsersList';
import MainContentContainer from '@/components/Common/BaseTemplate/MainContentContainer';
import UsersListPageHeader from './UsersListPageHeader/UsersListPageHeader';

export default function UsersListPage() {
  const dispatch = useAdminDispatch();
  const loadingStatus = useAdminSelector(selectUsersListLoadingStatus);

  useEffect(() => {
    dispatch(getUsersList());
  }, [dispatch]);

  if (loadingStatus !== LoadingStatus.Received) return <Preloader />;

  return (
    <MainContentContainer>
      <UsersListPageHeader />
      <UsersList />
    </MainContentContainer>
  );
}