import React, { useContext, useState } from 'react';
import BUnitContext, { BUnitContextProps } from '../../BUnitContext';
import ActionButton from '../ActionButton/ActionButton';
import styles from '../BUnitActionButtons.module.sass';
import CreateBUnitModal from '../../../../CreateBUnitSection/CreateBUnitModal/CreateBUnitModal';
import useCheckPermission from '@/components/App/Admin/hooks/checkPermission';

// interface CreateNewUnitInDepartmentActionButtonProps {

// }

export default function CreateNewUnitInDepartnentActionButton() {
  const { id } = useContext(BUnitContext) as Required<BUnitContextProps>;
  const [isModalOpen, toggleOpenModal] = useState(false);
  const checkPermission = useCheckPermission();

  const buttonHandleClick = () => {
    toggleOpenModal(true);
  };

  if (!checkPermission('create_units')) return null;

  return (
    <>
      <ActionButton
        buttonType="plus"
        className={styles['unit-button-field']}
        handleActionClick={buttonHandleClick}
      />
      <CreateBUnitModal
        isShowingModal={isModalOpen}
        toggleModal={toggleOpenModal}
        targetDepartmentId={id}
      />
    </>
  );
}