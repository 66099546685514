import React from 'react';
import PropTypes from 'prop-types';
import SupersetEvent from '@/components/App/Admin/Superset/SupersetEvens/SupersetEventsList/SupersetEvent/SupersetEvent';
import { sortListByTime } from '@/helpers/helpers';

function SupersetEventsList({ events, langId }) {
  const sortEventsByTime = (a, b) => sortListByTime(a.createdAt, b.createdAt, 'desc');

  return (
    <ul>
      {[...events]
        .sort(sortEventsByTime)
        .map(({ message, eventId, createdAt }) => (
          <SupersetEvent
            key={`${eventId}-${createdAt}`}
            message={message}
            createdAt={createdAt}
            langId={langId}
            eventId={eventId}
          />
        ))}
    </ul>
  );
}

SupersetEventsList.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string,
      eventId: PropTypes.number,
      message: PropTypes.string,
    }),
  ).isRequired,
  langId: PropTypes.string.isRequired,
};

export default SupersetEventsList;