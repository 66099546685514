import React, { useMemo, useRef, useState } from 'react';
import styles from './UnitsTree.module.sass';
import { selectUnitsTreeData } from '@/store/selectors/admin/superset/unitsTree';
import { useAdminSelector } from '@/store/hooks/adminRedux';
import useSplitedUnitsByType
  from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/DepartmentUnit/BUnitBranch/useSplitedUnitsByType';
import BranchUnitsGroup
  from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/DepartmentUnit/BUnitBranch/BranchUnitsGroup/BranchUnitsGroup';
import BUnitsSearch from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnitsSearch';
import { UnitId } from '@/store/types/user/units';
import UnitsTreeContext, {
  UnitsTreeContextProps,
} from '@/components/App/Admin/BUnitsSection/UnitsTree/UnitsTreeContext';
import Preloader from '../../SupersetSettings/Preloader';

export interface SearchingValue {
  id: UnitId;
  path: UnitId[];
}

interface UnitsTreeProps {
  isTreeLoaded: boolean;
}

export default function UnitsTree({
  isTreeLoaded,
}: UnitsTreeProps) {
  const { roots, units } = useAdminSelector((state) => selectUnitsTreeData(state));
  const containerRef = useRef(null);
  const [searchingValue, setSearchingValue] = useState<SearchingValue | undefined>(undefined);
  const [isAnimation, toggleAnimation] = useState<boolean>(false);
  const unitsByActorType = useSplitedUnitsByType(roots, units);

  const contextValue = useMemo<UnitsTreeContextProps>(
    () => ({
      searchingValue, setSearchingValue, isAnimation, toggleAnimation,
    }),
    [isAnimation, searchingValue],
  );

  if (!isTreeLoaded) return <Preloader />;

  return (
    <UnitsTreeContext.Provider value={contextValue}>
      <div className={styles['units-tree']} ref={containerRef}>
        <BUnitsSearch setSearchingValue={setSearchingValue} />
        <BranchUnitsGroup isRoot isActor>{unitsByActorType[1]}</BranchUnitsGroup>
        <BranchUnitsGroup isRoot isActor={false}>{unitsByActorType[0]}</BranchUnitsGroup>
      </div>
    </UnitsTreeContext.Provider>
  );
}