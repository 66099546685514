import React, {
  Dispatch, SetStateAction, useCallback, useContext, useEffect, useState,
} from 'react';
import '../BUnitStyles.sass';
// import styles from './DepartmentUnit.module.sass';
import DepartmentUnitControlByType from './DepartmentUnitControlByType/DepartmentUnitControlByType';
import BUnitBranch from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/DepartmentUnit/BUnitBranch/BUnitBranch';
import newBUnitContext, { BUnitContextProps } from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitContext';
import { UnitId } from '@/store/types/user/units';
import BUnitContainer from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitContainer/BUnitContainer';
import UnitsTreeContext from '@/components/App/Admin/BUnitsSection/UnitsTree/UnitsTreeContext';

interface DepartmentUnitProps {
  toggleChildren: (arg: UnitId) => void;
}

export type ToggleOpenType = () => void;

export type ToggleIsInputType = Dispatch<SetStateAction<boolean>>;

function DepartmentUnit({ toggleChildren }: DepartmentUnitProps) {
  const { unit, id } = useContext(newBUnitContext) as Required<BUnitContextProps>;
  const [isOpen, toggleOpen] = useState<boolean>(unit.exp === 1);
  const [isInput, toggleIsInput] = useState<boolean>(false);
  const [isShowMenu, toggleMenu] = useState<boolean>(false);
  const { searchingValue } = useContext(UnitsTreeContext);

  const handleToggleOpen = useCallback(() => {
    toggleChildren(id);
    toggleOpen((prevState) => !prevState);
  }, [id, toggleChildren]);

  useEffect(() => {
    if (!searchingValue) return;
    if (isOpen) return;
    if (!searchingValue.path.includes(id)) return;
    handleToggleOpen();
  }, [handleToggleOpen, id, isOpen, searchingValue]);

  return (
    <div>
      <BUnitContainer
        isShowMenu={isShowMenu}
        toggleMenu={toggleMenu}
        toggleInput={toggleIsInput}
      >
        <DepartmentUnitControlByType
          isInput={isInput}
          toggleInput={toggleIsInput}
          toggleOpen={handleToggleOpen}
        />
      </BUnitContainer>
      <BUnitBranch
        isOpen={isOpen}
        childrenUnits={unit.children}
      />
    </div>
  );
}

export default DepartmentUnit;