import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setUploadFileProgress } from '@/store/actions/uploads/uploads';

const initialState: UploadsInitialState = {
  uploadFilesProgress: {},
};
export const uploadsSlise = createSlice({
  name: 'uploads',
  initialState,
  reducers: {
    deleteFileFromUploads: (state, { payload }: PayloadAction<string>) => {
      if (state.uploadFilesProgress[payload]) delete state.uploadFilesProgress[payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setUploadFileProgress, (state, { payload }: PayloadAction<SetFileProgressArgs>) => {
        const { fileName, progress } = payload;
        state.uploadFilesProgress[fileName] = progress;
      });
  },
});

const { reducer } = uploadsSlise;

export default reducer;