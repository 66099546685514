// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".summary--R6Jc6{position:relative;display:flex;justify-content:space-between;list-style:none;padding:0.3125rem 0.3125rem 0.3125rem 0.3125rem;transition:background-color .3s ease 0s}.summary--R6Jc6.invalid--yhtDq{padding-top:0.75rem}.summary--R6Jc6:hover{background:var(--hover-content-block-bg);cursor:pointer}.summary--R6Jc6::-webkit-details-marker{display:none}.summary--R6Jc6 .invalid-banner--ZkLLI{position:absolute;width:100%;top:0;left:0;font-size:0.625rem;background:var(--error-color);color:#fff}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/SuperAdmin/UsersListPage/UsersList/SuperAdminUser/SuperAdminUserSummary/SuperAdminUserSummary.module.sass"],"names":[],"mappings":"AAEA,gBACE,iBAAA,CACA,YAAA,CACA,6BAAA,CACA,eAAA,CACA,+CAAA,CACA,uCAAA,CACA,+BACE,mBAAA,CACF,sBACE,wCAAA,CACA,cAAA,CACF,wCACE,YAAA,CACF,uCACE,iBAAA,CACA,UAAA,CACA,KAAA,CACA,MAAA,CACA,kBAAA,CACA,6BAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"summary": "summary--R6Jc6",
	"invalid": "invalid--yhtDq",
	"invalid-banner": "invalid-banner--ZkLLI"
};
export default ___CSS_LOADER_EXPORT___;
