import { CreateWebinarFielsNamesEnum, CreateWebinarFilesPlaces, CreateWebinarFormSectionType } from '../types/webinarPageTypes';

export const FieldTitleByName: Record<CreateWebinarFielsNamesEnum, string> = {
  [CreateWebinarFielsNamesEnum.Date]: 'Дата проведения вебинара',
  [CreateWebinarFielsNamesEnum.WebinarName]: 'Название вебинара',
  [CreateWebinarFielsNamesEnum.WebinarKey]: 'Id вебинара',
  [CreateWebinarFielsNamesEnum.IsActive]: 'Страница регистрации активна',
  [CreateWebinarFielsNamesEnum.ShowLanding]: 'Показывать на лендинге',
  [CreateWebinarFielsNamesEnum.Location]: 'Место проведения',
  [CreateWebinarFielsNamesEnum.Title]: 'Заголовок описания вебинара',
  [CreateWebinarFielsNamesEnum.Description]: 'Описание вебинара',
  [CreateWebinarFielsNamesEnum.RegisterText]: 'Текст страницы регистрации',
  [CreateWebinarFielsNamesEnum.SpeackerName]: 'Имя спикера',
  [CreateWebinarFielsNamesEnum.SpeackerInfo]: 'Информация о спикере',
};

export const WebinarInputTypeByName: Record<CreateWebinarFielsNamesEnum, HTMLInputElement['type']> = {
  [CreateWebinarFielsNamesEnum.Date]: 'text',
  [CreateWebinarFielsNamesEnum.IsActive]: 'checkbox',
  [CreateWebinarFielsNamesEnum.ShowLanding]: 'checkbox',
  [CreateWebinarFielsNamesEnum.WebinarName]: 'text',
  [CreateWebinarFielsNamesEnum.WebinarKey]: 'text',
  [CreateWebinarFielsNamesEnum.Location]: 'text',
  [CreateWebinarFielsNamesEnum.Title]: 'text',
  [CreateWebinarFielsNamesEnum.Description]: 'text',
  [CreateWebinarFielsNamesEnum.RegisterText]: 'text',
  [CreateWebinarFielsNamesEnum.SpeackerName]: 'text',
  [CreateWebinarFielsNamesEnum.SpeackerInfo]: 'text',
};

export const WebinarFilesPlacesBySection = {
  [CreateWebinarFormSectionType.Meta]: CreateWebinarFilesPlaces.Logo,
  [CreateWebinarFormSectionType.Speaker]: CreateWebinarFilesPlaces.Speaker,
  [CreateWebinarFormSectionType.Content]: CreateWebinarFilesPlaces.Banner,
};

export const WebinarMetaFieldsNames = [
  CreateWebinarFielsNamesEnum.WebinarKey,
  CreateWebinarFielsNamesEnum.IsActive,
  CreateWebinarFielsNamesEnum.ShowLanding,
  CreateWebinarFielsNamesEnum.Date,
  CreateWebinarFielsNamesEnum.WebinarName,
  CreateWebinarFielsNamesEnum.Location,
];

export const WebinarSpeakerFieldsNames = [
  CreateWebinarFielsNamesEnum.SpeackerName,
  CreateWebinarFielsNamesEnum.SpeackerInfo,
];

export const WebinarDescripionFieldsNames = [
  CreateWebinarFielsNamesEnum.Title,
  CreateWebinarFielsNamesEnum.Description,
  CreateWebinarFielsNamesEnum.RegisterText,
];

export const CreateWebinarSectionTitle = {
  [CreateWebinarFormSectionType.Meta]: 'Мета Информция',
  [CreateWebinarFormSectionType.Speaker]: 'Информация о спикере',
  [CreateWebinarFormSectionType.Content]: 'Описание вебинара',
};

export const CreateWebinarValidation = {

};
export const ExtByName = {
  [CreateWebinarFilesPlaces.Banner]: [
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/bmp',
    'image/tiff',
    'image/svg+xml',
  ],
  [CreateWebinarFilesPlaces.Video]: ['video/mp4'],
  [CreateWebinarFilesPlaces.Logo]: [
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/bmp',
    'image/tiff',
    'image/svg+xml',
  ],
  [CreateWebinarFilesPlaces.Speaker]: [
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/bmp',
    'image/tiff',
    'image/svg+xml',
  ],
};