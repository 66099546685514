import React from 'react';
import { CreateWebinarFilesPlaces } from '../../types/webinarPageTypes';
import UploadingInputWithGallerey from '@/components/App/Admin/SuperAdmin/SuperUploading/types/consts/hooks/actions/UploadingInput';
import styles from './WebinarFilesInput.module.sass';
import { useMultipleFilesUpload } from '../../hooks/useFilesUpload';
import { ExtByName } from '../../consts/createWebinarPageConsts';
import { WebinarType } from '@/store/types/admin/webinars';

interface WebinarFilesInputProps {
  webinar: WebinarType;
  place: CreateWebinarFilesPlaces;
  webinarId: string
}

const TitleByPlace = {
  [CreateWebinarFilesPlaces.Video]: 'Видео',
  [CreateWebinarFilesPlaces.Logo]: 'Лого',
  [CreateWebinarFilesPlaces.Banner]: 'Контент',
  [CreateWebinarFilesPlaces.Speaker]: 'Спикер',
};

export default function WebinarFilesInput({ webinar, place, webinarId }: WebinarFilesInputProps) {
  const uploadMultipleFiles = useMultipleFilesUpload();

  const uploadFiles = (newFiles: File[]) => {
    uploadMultipleFiles(newFiles.map((file) => ({
      file,
      place,
      entity: webinarId as string,
    })));
  };

  return (
    <div>
      <div className={styles['input-title']}>{TitleByPlace[place]}</div>
      <UploadingInputWithGallerey
        uploadedFiles={webinar?.files?.[place] || []}
        uploadFiles={uploadFiles}
        acceptFilesExt={ExtByName[place]}
        id={`${place}-files`}
      />
    </div>

  );
}