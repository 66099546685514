import React, { useState } from 'react';
import styles from './BUnitActionMenu.module.sass';
import CustomSelect from '@/components/App/Admin/BUnitsSection/CustomSelect';
import BUnitActionsConfirmButton from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnitActionMenu/BUnitActionsConfirmButton/BUnitActionsConfirmButton';
import useLocalStorageByAdmin from '@/components/App/Admin/BUnitsSection/UnitsTree/hooks/adminLocalStorageSaver';
import { selectSelectedUnits } from '@/store/selectors/admin/superset/unitsTree';
import CustomSelectOption from '@/components/App/Admin/BUnitsSection/CustomSelect/CustomSelectOption/CustomSelectOption';
import { useAdminSelector } from '@/store/hooks/adminRedux';
import useBUnitGroupAction, { BUnitGroupAction } from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnitActionMenu/useBUnitGroupAction';
import BUnitActionModal from './BUnitActionsConfirmButton/BUnitActionModal/BUnitActionModal';

export default function BUnitActionMenu() {
  const [writeInLocal] = useLocalStorageByAdmin<BUnitGroupAction>('unitsActions');
  const [activeAction, setActiveActions, bUnitsActions] = useBUnitGroupAction();
  const [isShowingModal, toggleModal] = useState(false);
  const [isOpen, toggleOpen] = useState(false);
  const selectedUnits = useAdminSelector(selectSelectedUnits);

  const selectActiveAction = (selectedAction: BUnitGroupAction) => {
    setActiveActions(selectedAction);
    writeInLocal(selectedAction);
    toggleOpen(false);
  };

  if (!selectedUnits.length) return null;

  if (!activeAction) return null;

  return (
    <div className={styles['action-menu-block']}>
      <div className={styles['select-block']}>
        <CustomSelect
          activeValue={activeAction.name}
          type="button"
          mainBtnClassNames={styles['select-button']}
          isOpen={isOpen}
          toggleOpen={toggleOpen}
        >
          {bUnitsActions.map((action: BUnitGroupAction) => (
            <CustomSelectOption
              key={action.operationType}
              onClick={() => selectActiveAction(action)}
              optionClassNames={styles['select-option']}
              isCurrentValue={action.operationType === activeAction.operationType}
            >
              {action.name}
            </CustomSelectOption>
          ))}
        </CustomSelect>
      </div>
      <BUnitActionsConfirmButton
        operationType={activeAction.operationType}
        toggleModal={toggleModal}
      />
      <BUnitActionModal
        isShowingModal={isShowingModal}
        toggleModal={toggleModal}
        operationType={activeAction.operationType}
      />
    </div>
  );
}