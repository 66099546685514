// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".upload-drop-container--Ee-di{display:flex;flex-direction:column;align-items:baseline;border:1px dashed var(--secondary-color);border-radius:0.75rem;gap:0.75rem;background:var(--admin-btn-bg);width:100%;color:var(--admin-btn-color)}.upload-drop-container--Ee-di .files-hidden-input--\\+fDUP{display:none}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/SuperAdmin/SuperUploading/types/consts/hooks/actions/UploadingInput/UploadingInputWithGallerey.module.sass"],"names":[],"mappings":"AAEA,8BACE,YAAA,CACA,qBAAA,CACA,oBAAA,CACA,wCAAA,CACA,qBAAA,CACA,WAAA,CACA,8BAAA,CACA,UAAA,CACA,4BAAA,CACA,0DACE,YAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upload-drop-container": "upload-drop-container--Ee-di",
	"files-hidden-input": "files-hidden-input--+fDUP"
};
export default ___CSS_LOADER_EXPORT___;
