import React, { useState } from 'react';
import styles from './BUnitSpec.module.sass';
import BUnitSpecInput
  from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardFields/BUnitFieldsContent/BUnitFieldsSections/BUnitSpec/BUnitSpecInput/BUnitSpecInput';
import BUnitSpecDropdown
  from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardFields/BUnitFieldsContent/BUnitFieldsSections/BUnitSpec/BUnitSpecDropdown/BUnitSpecDropdown';
import { getClearClassNames } from '@/helpers/helpers';

export enum SpecType {
  New = 'new',
  Deleted = 'deleted',
  Current = 'current',
}
interface BUnitSpecProps {
  spec: string[];
  index: number;
  removeSpec: (index: number) => void;
  updateSpec?: (newSpec: string, index: number) => void
  isDisable?: boolean
  inModal?: boolean;
  type?: SpecType,
}

function BUnitSpec({
  spec,
  index,
  removeSpec,
  updateSpec,
  isDisable = false,
  inModal = false,
  type = SpecType.Current,
}: BUnitSpecProps) {
  const [specValue, setSpecValue] = useState<string>(spec[index]);
  const [isDropdownOpen, toggleDropdown] = useState(false);

  const handleRemoveSpec = () => {
    removeSpec(index);
  };

  const handleUpdateSpec = (newSp: string) => {
    if (!updateSpec) return;
    updateSpec(newSp.trim(), index);
    setSpecValue(newSp.trim());
    toggleDropdown(false);
  };
  const onBlur = () => {
    if (isDisable) return;
    const trimmedValue = specValue.trim();
    toggleDropdown(false);
    const indexOfSpec = spec.indexOf(trimmedValue);
    if (
      !trimmedValue || (indexOfSpec >= 0 && indexOfSpec !== index)
    ) {
      removeSpec(index);
      return;
    }
    handleUpdateSpec(trimmedValue);
  };

  return (
    <li
      className={getClearClassNames([
        styles.spec,
      ])}
      onBlur={onBlur}
    >
      <BUnitSpecInput
        isDisable={isDisable}
        spec={specValue}
        type={type}
        isNew={specValue === '' && index === spec.length - 1}
        setSpecValue={setSpecValue}
        toggleDropdown={toggleDropdown}
      />
      <button
        type="button"
        onClick={handleRemoveSpec}
        className={styles['remove-spec-btn']}
      >
        {' '}
        <span className="icon-remove-light" />
      </button>
      {isDropdownOpen && (
      <BUnitSpecDropdown
        currentSpec={spec[index]}
        inputValue={specValue}
        handleUpdateSpec={handleUpdateSpec}
        unitSpecs={spec}
        inModal={inModal}
      />
      )}
    </li>

  );
}

export default BUnitSpec;