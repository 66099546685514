// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".upload-file-label--uHYth{display:flex;flex-direction:column;align-items:center;font-size:0.875rem;padding:1rem;border-top:1px dashed var(--secondary-color);gap:0.625rem;width:25rem;background:var(--admin-btn-bg);color:var(--admin-btn-color);height:fit-content;width:100%;border-radius:0 0 0.75rem 0.75rem}.upload-file-label--uHYth .upload-icon--D0V3f{font-size:1.25rem}.upload-file-label--uHYth:hover{background:var(--admin-btn-hover);color:var(--admin-btn-hover-color)}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/SuperAdmin/SuperUploading/types/consts/hooks/actions/UploadingInput/UploadingInputLabel/UploadingInputLabel.module.sass"],"names":[],"mappings":"AAEA,0BACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,kBAAA,CACA,YAAA,CACA,4CAAA,CACA,YAAA,CACA,WAAA,CACA,8BAAA,CACA,4BAAA,CACA,kBAAA,CACA,UAAA,CACA,iCAAA,CACA,8CACE,iBAAA,CACF,gCACE,iCAAA,CACA,kCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upload-file-label": "upload-file-label--uHYth",
	"upload-icon": "upload-icon--D0V3f"
};
export default ___CSS_LOADER_EXPORT___;
