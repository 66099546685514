import { createSlice } from '@reduxjs/toolkit';
import {
  createBUnit,
  deleteUnit,
  getRelatedUnits,
  getSupersetUnits,
  makeUnitsTree,
  selectTreeUnits,
  updateTreeUnits, updateUnitRoles,
} from '@/store/actions/admin/superset/unitsTree';
import { clearSuperset } from '@/store/actions/admin';
import parseUnits from '@/components/Common/UnitsTree/parseUnits';
import { TreeUnit, TreeUnits, UnitsTreeInitialState } from '@/store/types/admin/superset/unitsTree';

const initialState: UnitsTreeInitialState = {
  unitsArray: [],
  units: {},
  order: [],
  selectedUnits: [],
  roots: [],
};

export const unitTreeSlice = createSlice({
  name: 'admin/superset/units',
  initialState,
  reducers: {
    makeUnitsTree: (state, { payload }) => {
      const { order, units, roots } = payload;
      state.units = units;
      state.order = order;
      state.roots = roots;
    },
    updateLocalUnitName: (state, { payload }) => {
      const { newName, id } = payload;
      state.units[id].name = newName;
    },
    transferUnitsInLocal: (state, { payload }) => {
      const { targetDepartmentId, unitsData } = payload;
      const newUnits: TreeUnits = {};
      const newChildren = new Set(state.units?.[targetDepartmentId]?.children || []);
      const newRoots = new Set(state.roots);

      unitsData.forEach(({ ID }: { ID: string }) => {
        const newUnit = { ...state.units[ID] };
        const oldParentId = newUnit.parent;

        if (oldParentId === targetDepartmentId) return;

        newUnit.parent = targetDepartmentId;
        newUnits[ID] = newUnit;
        if (oldParentId) {
          const oldParent = newUnits[oldParentId] ? newUnits[oldParentId] : state.units[oldParentId];
          newUnits[oldParentId] = {
            ...oldParent,
            children: oldParent.children?.filter((id) => id !== ID),
          };
        }
        if (!oldParentId) newRoots.delete(ID);
        newChildren.add(ID);
      });

      newUnits[targetDepartmentId] = { ...state.units[targetDepartmentId], children: Array.from(newChildren) };
      state.roots = Array.from(newRoots);
      state.units = { ...state.units, ...newUnits };
    },
    rightUnitInLocal: (state, { payload }) => {
      const {
        name, departments, targetParentId, unitId, isActor,
      } = payload;
      const minElement = Math.min(...state.order.map((id) => Number(id)));
      // @ts-ignore
      const newUnit: TreeUnit = {
        name,
        departments,
        level: departments ? departments.length : 0,
        isunit: isActor ? 0 : 1,
      };
      const newUnitId = unitId || `${minElement - 1}`;
      if (targetParentId) newUnit.parent = targetParentId;
      const parentChildren = state.units[targetParentId].children || [];
      const lastParentChildId = parentChildren.length
        ? parentChildren[parentChildren.length - 1]
        : '';
      const newDepartmentTargetIndex = lastParentChildId
        ? state.order.indexOf(lastParentChildId)
        : state.order.indexOf(targetParentId);
      state.units[targetParentId].children = [...parentChildren, newUnitId];
      state.units[newUnitId] = { ...newUnit };
      state.order = [
        ...state.order.slice(0, newDepartmentTargetIndex),
        newUnitId,
        ...state.order.slice(newDepartmentTargetIndex),
      ];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(makeUnitsTree, (state) => {
        const { order, units, roots } = parseUnits([...state.unitsArray]);
        // @ts-ignore
        state.units = units;
        state.order = order;
        state.roots = roots;
      })
      .addCase(getSupersetUnits.fulfilled, (state, { payload }) => {
        if (!payload) return;
        state.unitsArray = payload;
        // @ts-ignore
        state.selectedUnits = payload.flatMap(({ id, selected }) => {
          if (!selected) return [];
          return id;
        });
      })
      .addCase(selectTreeUnits.fulfilled, (state, { payload }) => {
        const { unitsData, isSelect } = payload;
        state.selectedUnits = isSelect
          ? [...state.selectedUnits, ...unitsData]
          : [...state.selectedUnits].filter((id) => !unitsData.includes(id));
        unitsData.forEach((id) => {
          // @ts-ignore
          state.units[id].selected = isSelect;
        });
      })
      .addCase(updateTreeUnits.fulfilled, (state, { payload }) => {
        payload.forEach((unitUpdateInfo) => {
          const { newParentId, ID: id, ...newData } = unitUpdateInfo;

          const unit = { ...state.units[id] };

          if (newParentId) {
            const oldParentId = unit.parent;
            if (oldParentId) {
              state.units[oldParentId].children = state.units[oldParentId].children?.filter((childId) => childId !== id);
            }
            const children = state.units[newParentId].children ? state.units[newParentId].children : [];

            state.units[newParentId].children = [...children, id];
            unit.parent = newParentId;
          }

          state.units[id] = { ...unit, ...newData };
        });
      })
      .addCase(getRelatedUnits.fulfilled, (state, { payload }) => {
        const {
          roles, rolesList, unitId,
        } = payload;
        state.units[unitId].rolesList = rolesList;
        state.units[unitId].isRating = roles.estimator;
        state.units[unitId].isRatedBy = roles.estimated;
      })
      .addCase(clearSuperset, () => initialState)
      .addCase(createBUnit.fulfilled, (state, { payload }) => {
        const {
          targetDepartmentId,
          ...unitInfo
        } = payload;
        const {
          id,
          // @ts-ignore
          n,
          // @ts-ignore
          d,
          isEstimated,
          isEstimator,
          secret,
          selected,
          hasStarted,
          hasFinished,
          // @ts-ignore
          u,
          specs,
        } = unitInfo;
        // todo как будет правка с бэка получать spec оттуда
        const newUnit = {
          isunit: u,
          departments: d,
          name: n,
          isEstimator,
          isEstimated,
          level: d ? d.length : 0,
          secret,
          exp: 0,
          // @ts-ignore
          spec: specs,
          selected,
          hasStarted,
          hasFinished,
          ...(targetDepartmentId ? { parent: targetDepartmentId } : {}),
        };
        // @ts-ignore
        state.unitsArray = [...state.unitsArray, unitInfo];
        // @ts-ignore

        if (targetDepartmentId) {
          const newChildren = state.units[targetDepartmentId].children
            ? [...state.units[targetDepartmentId].children, id]
            : [id];
          state.units[targetDepartmentId].children = [...newChildren];
        }
        // @ts-ignore
        state.units[id] = newUnit;
        if (newUnit.level === 0 && targetDepartmentId) state.roots = [...state.roots, id];
      })
      .addCase(deleteUnit.fulfilled, (state, { payload }) => {
        const { unitId } = payload;
        state.unitsArray = [...state.unitsArray].filter(
          ({ id }) => id !== unitId,
        );
        state.order = [...state.order].filter((id) => id !== unitId);
        const unit = state.units[unitId];
        if (unit.parent) {
          state.units[unit.parent].children = [
            // @ts-ignore
            ...state.units[unit.parent].children,
          ].filter((id) => unitId !== id);
        }
        delete state.units[unitId];
      })
      .addCase(updateUnitRoles.fulfilled, (state, { payload }) => {
        const { unitId, type, unitData } = payload;
        if (type === 'estimator') {
          state.units[unitId].isRating = { ...state.units[unitId].isRating, ...unitData };
        } else {
          state.units[unitId].isRatedBy = { ...state.units[unitId].isRatedBy, ...unitData };
        }
      });
  },
});

const { reducer } = unitTreeSlice;
export default reducer;