import React from 'react';
import styles from './CreateWebinarPage.module.sass';
import CreateWebinarForm from './CreateWebinarForm/CreateWebinarForm';

function CreateWebinarPage() {
  return (
    <div className={styles['create-form-container']}>
      <CreateWebinarForm />
    </div>
  );
}

export default CreateWebinarPage;