// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".user--69kmV{position:relative;display:flex;flex-direction:column;border-top:.5px solid var(--dark-grey-line-color)}.user--69kmV.invalid--voqsD{border:1px solid var(--error-color)}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/SuperAdmin/UsersListPage/UsersList/SuperAdminUser/SuperAdminUser.module.sass"],"names":[],"mappings":"AAEA,aACE,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,iDAAA,CACA,4BACE,mCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user": "user--69kmV",
	"invalid": "invalid--voqsD"
};
export default ___CSS_LOADER_EXPORT___;
