import { createAsyncThunk } from '@reduxjs/toolkit';
import { setProjectSettingByAnswer } from '@/store/actions/user/projects';
import { getDateInPostgreFormat } from '@/helpers/helpers';
import {
  OptOutOfEvaluationPayload, SentProtocols, SetAnswerArgs,
  SetAnswerPayload,
  SetProtocolArgs,
  SetRelationArgs,
  SetSeveralProtocolsArgs, SetSeveralRelationPayload, SetTestBlockAnswer,
} from '@/store/types/user/protocols';
import { PlayerState } from '@/store/player';
import axiosProcessing from '@/axiosConfig';
import { ActorProfile, Project } from '@/store/types/user/actor';

export const setProtocol = createAsyncThunk(
  'actor/setProtocol',
  async ({ id, value }: SetProtocolArgs) => ({ id, value }),
);

export const setRelations = createAsyncThunk(
  'actor/setRelations',
  async ({ id, value, unitId }: SetRelationArgs) => ({ id, value, unitId }),
);

export const setAnswer = createAsyncThunk(
  'actor/setAnswer',
  async ({
    id, value, unitId, formType = '',
  }: SetAnswerArgs, { getState, dispatch }) => {
    if (unitId) {
      await dispatch(setRelations({ id, value, unitId }));
    } else {
      await dispatch(setProtocol({ id, value }));
    }

    const state = getState() as PlayerState;
    const { profile, project } = state.user.actor;
    if (!profile) return { id, unitId };
    const { settings, projectId } = project as Project;
    if (settings[id]) {
      dispatch(setProjectSettingByAnswer({ settingId: id, value }));
    }
    const { id: actorId } = profile;
    return {
      actorId,
      formType,
      id,
      unitId,
      projectId,
    };
  },
);

export const setSeveralRelations = createAsyncThunk(
  'actor/setSeveralRelations',
  async (data: SetSeveralRelationPayload) => data,
);

export const setSeveralProtocols = createAsyncThunk(
  'actor/setSeveralProtocols',
  async (data: SetSeveralProtocolsArgs, { getState }) => {
    const state = getState() as PlayerState;
    const { itemDeps } = state.user.pages;
    return { ...data, itemDeps };
  },
);

export const optOutOfEvaluation = createAsyncThunk(
  'actor/optOutOfEvaluation',
  async (data: OptOutOfEvaluationPayload) => {
    const { questions, unitId } = data;
    return { questions, unitId };
  },
);

export const deleteAnswer = createAsyncThunk(
  'actor/deleteAnswer',
  async ({ id, unitId }: SetAnswerPayload) => ({
    id,
    unitId,
  }),
);

export const setTestBlockAnswer = createAsyncThunk(
  'actor/setTestBlockAnswer',
  async ({ id, value }: SetProtocolArgs, { rejectWithValue, getState }) => {
    try {
      const state = getState() as PlayerState;
      const { isDemo } = state.user.actor;
      const answer: [SetTestBlockAnswer] = [
        {
          itemId: id,
          answer: value,
          time: getDateInPostgreFormat(),
        },
      ];

      await axiosProcessing.post('/api/actor/go/protocols', {
        protocols: answer,
        time: getDateInPostgreFormat(),
        isDemo,
      });

      return answer[0];
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const sendAnswers = createAsyncThunk(
  'actor/protocols/sendAnswers',
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as PlayerState;
    const { isDemo } = state.user.actor;
    const { updatedItems, updatedRelations, protocolsTime } = state.user.protocols;
    const { id: actorId } = state.user.actor.profile as ActorProfile;
    const { projectId } = state.user.actor.project as Project;
    const body: { protocols: SentProtocols, isDemo: boolean } = {
      protocols: [],
      isDemo,
    };
    Object.keys(updatedItems).forEach((key) => {
      body.protocols.push({
        itemId: key,
        answer: updatedItems[key],
        // @ts-ignore
        time: protocolsTime[key],
      });
    });
    Object.keys(updatedRelations).forEach((itemId) => {
      Object.keys(updatedRelations[itemId]).forEach((unitId) => {
        // @ts-ignore
        const time = unitId.includes('|') ? protocolsTime[`${itemId}||comment`]?.[unitId] || '' : protocolsTime[itemId]?.[unitId] || '';
        const formatUnitId = unitId.includes('|')
          ? unitId.split('|')[0]
          : unitId;
        body.protocols.push({
          itemId,
          answer: updatedRelations[itemId][unitId],
          unitId: formatUnitId,
          time,
        });
      });
    });
    if (!body.protocols.length) {
      return {
        actorId,
        projectId,
        sentProtocols: body.protocols,
      };
    }
    try {
      const response = await axiosProcessing.post<SentProtocols>('/api/actor/go/protocols', {
        ...body,
        time: getDateInPostgreFormat(),
      });

      return {
        actorId,
        projectId,
        sentProtocols: response.data,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getProtocols = createAsyncThunk(
  'actor/getProtocols',
  async (_, { rejectWithValue, getState }) => {
    try {
      const response = await axiosProcessing.get<SentProtocols>('/api/actor/go/protocols');
      const state = getState() as PlayerState;
      const { id: actorId } = state.user.actor.profile as ActorProfile;
      const { projectId } = state.user.actor.project as Project;
      const degreeItemsByPagesFromState = state.user.pages.degreeItemsByPages;
      return {
        data: response.data, degreeItemsByPagesFromState, actorId, projectId,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);