import { combineReducers } from '@reduxjs/toolkit';
import webinar from '@/components/App/Webinar/CreateWebinarPage/reducers/webinar';
import usersList from '@/components/App/Admin/SuperAdmin/UsersListPage/reducers';

export interface SuperReducersState {
  webinar: ReturnType<typeof webinar>;
  usersList: ReturnType<typeof usersList>;
}

const superReducers = combineReducers<SuperReducersState>({
  webinar,
  usersList,
});

export default superReducers;