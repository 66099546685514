import { useMemo } from 'react';
import { UnitId } from '@/store/types/user/units';
import { TreeUnits } from '@/store/types/admin/superset/unitsTree';

export const sortUnits = (unitsId: UnitId[], units: TreeUnits) => [...unitsId].sort((a, b) => {
  if (units[a].name < units[b].name) {
    return -1;
  }
  if (units[a].name > units[b].name) {
    return 1;
  }
  return 0;
});

const useSplitedUnitsByType = (childrenUnits: UnitId[] | undefined, units: TreeUnits) => useMemo(
  () => {
    if (!childrenUnits?.length) return [[], []];
    const actorUnits: UnitId[] = [];
    const noActorUnits: UnitId[] = [];
    for (let i = 0; i < childrenUnits.length; i += 1) {
      const id = childrenUnits[i];
      if (units[id]) {
        if (units[id].isunit === 1) {
          actorUnits.push(id);
        } else {
          noActorUnits.push(id);
        }
      }
    }
    return [sortUnits(noActorUnits, units), sortUnits(actorUnits, units)];
  },
  [childrenUnits, units],
);

export default useSplitedUnitsByType;