import React from 'react';
import styles from './Preloader.module.sass';
import { getClearClassNames } from '@/helpers/helpers';

interface PreloaderProps {
  externalClass?: string;
}

function Preloader({ externalClass = '' }: PreloaderProps) {
  return (
    <div className={getClearClassNames([
      styles.loader,
      externalClass,
    ])}
    />
  );
}

export default Preloader;