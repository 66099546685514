import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './BtnCheckboxSlider.module.sass';

function BtnCheckboxSlider({
  onChange = () => {},
  checked,
  externalClassNames = null,
  disabled = false,
  notInteractive = false,
}) {
  const [isEnabled, setSupersetStatus] = useState(checked);

  const onToggle = (e) => {
    if (disabled || notInteractive) {
      e.preventDefault();
      return false;
    }

    setSupersetStatus(!isEnabled);
    onChange();

    return false;
  };

  return (
    <button
      type="button"
      aria-label="btn checkbox slider"
      className={`${styles['btn-checkbox-slider']} ${
        isEnabled ? styles.enabled : ''
      } ${externalClassNames}
       ${notInteractive ? styles['not-interactive'] : ''} btn-without-style`}
      onClick={onToggle}
      disabled={disabled || notInteractive}
    />
  );
}

BtnCheckboxSlider.propTypes = {
  externalClassNames: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  notInteractive: PropTypes.bool,
};

export default BtnCheckboxSlider;