// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".info-item--23q0R{font-size:0.875rem;color:var(--secondary-color);text-align:right}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/SuperAdmin/UsersListPage/UsersList/SuperAdminUser/SuperAdminUserSummary/SuperAdmnUsetMetaInfo/SuperAdminUserInfoItem/SuperAdminUserInfoItem.module.sass"],"names":[],"mappings":"AAEA,kBACE,kBAAA,CACA,4BAAA,CACA,gBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info-item": "info-item--23q0R"
};
export default ___CSS_LOADER_EXPORT___;
