import React from 'react';
import { useForm } from 'react-hook-form';
import { User } from '../../../types/usersList';
import styles from './SuperAdminUserForm.module.sass';
import { AdminUserEditFormType, FormFieldNames } from './types/SuperAdminFormTypes';
import SuperAdminUserInput from './SuperAdminUserInput/SuperAdminUserInput';

interface SuperAdminUserFormProps {
  user: User;
}

export default function SuperAdminUserForm({ user }: SuperAdminUserFormProps) {
  const formProps = useForm<AdminUserEditFormType>();
  const { id } = user;

  return (
    <form className={styles['user-form']}>
      {
        Object.values(FormFieldNames).map((name) => (
          <SuperAdminUserInput
            id={`${name}-${id}`}
            name={name}
            defaultValue={user[name]}
            {...formProps}
          />
        ))
      }
    </form>
  );
}