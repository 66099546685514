// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".files-list--jsJXF{display:grid;grid-template-columns:repeat(auto-fit, minmax(150px, 1fr));width:100%;padding:0.75rem;max-height:31.25rem;height:max-content;grid-gap:0.75rem;gap:0.75rem;overflow-y:scroll}.files-list--jsJXF:empty{height:1.25rem;opacity:0}.files-list--jsJXF .file--CoDdQ{text-align:right}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/SuperAdmin/SuperUploading/FilesForUploadList/FilesForUploadList.module.sass"],"names":[],"mappings":"AAEA,mBACE,YAAA,CACA,0DAAA,CACA,UAAA,CACA,eAAA,CACA,mBAAA,CACA,kBAAA,CACA,gBAAA,CAAA,WAAA,CACA,iBAAA,CACA,yBACE,cAAA,CACA,SAAA,CACF,gCACE,gBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"files-list": "files-list--jsJXF",
	"file": "file--CoDdQ"
};
export default ___CSS_LOADER_EXPORT___;
