import React from 'react';

const useUnload = (callback) => {
  React.useEffect(() => {
    window.addEventListener('beforeunload', callback);

    return () => {
      window.removeEventListener('beforeunload', callback);
    };
  }, [callback]);
};

export const useBlur = (callback) => {
  React.useEffect(() => {
    window.addEventListener('blur', callback);
    return () => {
      window.removeEventListener('blur', callback);
    };
  }, [callback]);
};
export default useUnload;