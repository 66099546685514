import { FormFieldNames } from '../types/SuperAdminFormTypes';

export const InputTypes = {
  [FormFieldNames.Name]: 'text',
  [FormFieldNames.Email]: 'email',
  [FormFieldNames.Company]: 'text',
};

export const InputTitles = {
  [FormFieldNames.Name]: 'Имя',
  [FormFieldNames.Email]: 'Email',
  [FormFieldNames.Company]: 'Компания',
};