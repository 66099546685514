import React, {
  ReactNode, useEffect, useRef, useState,
} from 'react';

interface BtnDropDownProps {
  children: [ReactNode | null, ReactNode | null],
  classNames: string,
  isOpen?: boolean,
  setIsOpen?: () => {},
}

function BtnDropDown({
  children = [null, null],
  classNames = '',
  isOpen: externalIsOpen,
  setIsOpen: externalSetIsOpen,
}: BtnDropDownProps) {
  const [internalIsOpen, internalSetIsOpen] = useState(false);

  // Determine whether to use internal or external state
  const isControlled = externalIsOpen !== undefined && externalSetIsOpen !== undefined;
  const isOpen = isControlled ? externalIsOpen : internalIsOpen;
  const setIsOpen = isControlled ? externalSetIsOpen : internalSetIsOpen;

  const dropDownRef = useRef(null);
  const dropdownButtonRef = useRef(null);

  const toggleDropDown = () => {
    setIsOpen(!isOpen);
  };

  let [button, dropDownList] = children;

  button = React.cloneElement(button, {
    onClick: toggleDropDown,
    ref: dropdownButtonRef,
  });
  dropDownList = React.cloneElement(dropDownList, { ref: dropDownRef });

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (
        dropDownRef.current !== null
        && !dropDownRef.current.contains(e.target)
        && !dropdownButtonRef.current.contains(e.target)
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      window.addEventListener('click', pageClickEvent);
    }

    return () => {
      window.removeEventListener('click', pageClickEvent);
    };
  }, [isOpen, setIsOpen]);

  return (
    <div className={`dropdown ${classNames}`}>
      {button}
      {isOpen && dropDownList}
    </div>
  );
}

export default BtnDropDown;