export enum FormFieldNames {
  Name = 'name',
  Email = 'email',
  Company = 'company',
}

export interface AdminUserEditFormType {
  [FormFieldNames.Name]: string;
  [FormFieldNames.Email]: string;
  [FormFieldNames.Company]: string;
}