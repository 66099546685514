import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import styles from './SuperAdminUserInput.module.sass';
import { AdminUserEditFormType, FormFieldNames } from '../types/SuperAdminFormTypes';
import { InputTitles, InputTypes } from '../consts/AdminUserFormConsts';

interface SuperAdminUserInputProps extends UseFormReturn<AdminUserEditFormType> {
  id: string;
  name: FormFieldNames;
  defaultValue: AdminUserEditFormType[keyof AdminUserEditFormType]
}
export default function SuperAdminUserInput({
  id, name, defaultValue, ...formProps
}: SuperAdminUserInputProps) {
  const { register } = formProps;
  return (
    <label
      className={styles['input-label']}
      htmlFor={id}
    >
      <div>{InputTitles[name]}</div>
      <input
        className={styles.input}
        id={id}
        defaultValue={defaultValue}
        type={InputTypes[name]}
        {...register(name)}
      />
    </label>
  );
}