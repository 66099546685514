import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import useFileUploadWithProgress from '@/hooks/filesUploadWithProgress';
import { useAdminDispatch } from '@/store/hooks/adminRedux';
import { setUploadFileProgress } from '@/store/actions/uploads/uploads';
import { webinarsSlise } from '../reducers/webinar';
import { CreateWebinarFilesPlaces } from '../types/webinarPageTypes';

export interface WebinarUploadFilePostForm {
  file: File;
  place: CreateWebinarFilesPlaces;
  entity: string;
}

export const useSingleFileUpload = () => {
  const uploadFileWithProgress = useFileUploadWithProgress();
  const dispatch = useAdminDispatch();
  const { webinarId } = useParams();

  const calcUploadProgress = (progressEvent: any): number => {
    const { loaded, total } = progressEvent;

    if (loaded === total) return 100;

    return Math.round((loaded * 100) / total);
  };

  const uploadFile = useCallback((data: WebinarUploadFilePostForm) => {
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('entity', data.entity);
    formData.append('place', data.place);

    const onUploadProgress = (progressEvent: any) => {
      // @ts-ignore
      dispatch(setUploadFileProgress({
        fileName: data.file.name,
        progress: calcUploadProgress(progressEvent),
      }));
    };

    const uploadPromise = uploadFileWithProgress({
      url: '/api/upload',
      formData,
      onUploadProgress,
    }).then((response) => {
      const { path } = response.content;

      if (webinarId) {
        // @ts-ignore
        dispatch(webinarsSlise.actions.addFileToWebinar({
          place: data.place,
          webinarId,
          path,
        }));
      }
    });

    return uploadPromise;
  }, [dispatch, uploadFileWithProgress, webinarId]);

  return uploadFile;
};

export const useMultipleFilesUpload = () => {
  const uploadFile = useSingleFileUpload();

  const uploadMultipleFiles = useCallback(async (files: WebinarUploadFilePostForm[]) => {
    try {
      return await Promise.all(files.map((fileData) => uploadFile(fileData)));
    } catch (err) {
      return err;
    }
  }, [uploadFile]);

  return uploadMultipleFiles;
};