import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styles from './CutsChart.module.sass';
import { getClearClassNames } from '@/helpers/helpers';
import Categories from '@/components/App/Admin/Results/Cuts/CutsChart/Categories';
import Answers from '@/components/App/Admin/Results/Cuts/CutsChart/Answers';
import {
  selectCutsByItemIDForActiveTypeCuts,
  selectIsNPSItem,
  selectNeedToCalcStatisticsValues,
} from '@/store/selectors/admin/superset/process/cuts/cuts';
import SVGChart from '@/components/App/Admin/Results/Cuts/SVGChart';
import StatisticsValues from '@/components/App/Admin/Results/Cuts/StatisticsValues';
import NPSscore from '@/components/App/Admin/Results/Cuts/CutsChart/NPSscore';

function CutsChart() {
  const { id: supersetID } = useParams();
  const { itemID, typeCuts } = useParams();
  const {
    answerStat,
    answerStatOther,
    answersCodes,
    answerText,
    answerCats,
    catsTexts,
  } = useSelector((state) => selectCutsByItemIDForActiveTypeCuts(state, itemID, typeCuts));
  const needToCalcStatisticsValues = useSelector((state) => selectNeedToCalcStatisticsValues(state, itemID, typeCuts));
  const isNPSItem = useSelector((state) => selectIsNPSItem(state, itemID, typeCuts));

  const areThereDataForUnselected = !answerStatOther.every(
    (stat) => stat === 0,
  );

  const areThereCategories = !!answerCats;

  return (
    <div
      className={getClearClassNames([
        styles['cuts-chart'],
        !areThereCategories ? styles['no-categories'] : null,
      ])}
    >
      {needToCalcStatisticsValues && (
        <StatisticsValues
          itemID={itemID}
          answerValues={answerStat}
          answerValuesOthers={answerStatOther}
          answerCodes={answersCodes}
          externalClassNames={{
            wrapStatisticsValues: styles['wrap-statistics-values'],
          }}
        />
      )}

      {/*
        TODO only for the project "Опросник в ЕВРОХИМ". In future, we're planning to add a field "isNPS" from backend
       */}

      {isNPSItem && supersetID !== '2254556841904701362' ? <NPSscore /> : null}

      {areThereCategories && (
        <Categories
          answerCats={answerCats}
          catsTexts={catsTexts}
          answerStat={answerStat}
        />
      )}

      <SVGChart
        values={answerStat}
        externalClasses={{ svgChart: styles['svg-chart'] }}
      />

      {areThereDataForUnselected && (
        <SVGChart
          chartForUnselected={areThereDataForUnselected}
          values={answerStatOther}
          externalClasses={{
            svgUnselectedChart: styles['svg-unselected-chart'],
          }}
        />
      )}

      <Answers answerText={answerText} answerStat={answerStat} />
    </div>
  );
}

export default CutsChart;