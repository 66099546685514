import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import LrDarkLogo from 'static/img/lr-logo-dark.svg';
import AzimuthLightLogo from 'static/img/azimuth-logo-light.svg';
import AzimuthDarkLogo from 'static/img/azimuth-logo-dark.svg';
import SiburLogo from 'static/img/logo_sibur.svg';
import LrFavicon from 'static/img/favicon/favicon-lr.svg';
import AzimuthFavicon from 'static/img/favicon/favicon-azimuth.svg';
import LrLightLogo from 'static/img/lr-logo-light.svg';
import useTheme from '@/theme/useTheme';
import LogoContainer
  from '@/components/Common/BaseTemplate/Header/HeaderContainer/HeaderLogo/LogoContainer/LogoContainer';
import styles from './HeaderLogo.module.sass';

const logo = {
  azimuth: {
    light: AzimuthLightLogo,
    dark: AzimuthDarkLogo,
  },
  'lost.report': {
    light: LrLightLogo,
    dark: LrDarkLogo,
  },
};

interface HeaderLogoProps {
  linkPath?: string
}
function HeaderLogo({ linkPath }: HeaderLogoProps) {
  const { theme } = useTheme();

  const logoType = useMemo(() => (window.location.host.includes('bssl')
    || window.location.host.includes('azimuth')
    ? 'azimuth'
    : 'lost.report'), []);

  const headerLogo = useMemo(() => {
    if (window.location.pathname.includes('siburdec2024')) return SiburLogo;
    return logo[logoType][theme];
  }, [logoType, theme]);

  return (
    <>
      <Helmet>
        <title>{logoType}</title>
        <link
          rel="icon"
          sizes="any"
          href={`${logoType === 'azimuth' ? AzimuthFavicon : LrFavicon}`}
        />
      </Helmet>
      <LogoContainer
        path={linkPath}
      >
        <img
          src={`${headerLogo}`}
          className={styles.logo}
          alt="logo"
        />
      </LogoContainer>
    </>
  );
}

export default HeaderLogo;