import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import CreateWebinarFormField from '../CreateWebinarFormField/CreateWebinarFormField';
import styles from './WebinarContentSection.module.sass';
import {
  CreateWebinarFieldsType, CreateWebinarFielsNamesEnum, CreateWebinarFilesPlaces, CreateWebinarFormSectionType,
} from '../../types/webinarPageTypes';
import WebinarContenPreview from './WebinarContentPreview/WebinarContenPreview';
import { CreateWebinarSectionTitle, WebinarFilesPlacesBySection } from '../../consts/createWebinarPageConsts';
import WebinarFilesInput from '../WebinarFileInput/WebinarFilesInput';
import { WebinarType } from '@/store/types/admin/webinars';

interface WebinarContentSectionProps extends UseFormReturn<CreateWebinarFieldsType> {
  names: CreateWebinarFielsNamesEnum[];
  sectionType: CreateWebinarFormSectionType;
  webinar: WebinarType;
}

export default function WebinarContentSection({
  names,
  sectionType,
  webinar,
  ...formProps
}: WebinarContentSectionProps) {
  const { webinarId } = useParams();

  return (
    <section className={styles['form-section']}>
      <h2>{CreateWebinarSectionTitle[sectionType]}</h2>
      <div className={styles['section-content']}>
        <div className={styles['fields-container']}>
          {names.map((name) => (
            <CreateWebinarFormField
              key={name}
              name={name}
              {...formProps}
            />
          ))}
          {webinarId && (
          <WebinarFilesInput
            webinarId={webinarId as string}
            webinar={webinar}
            place={WebinarFilesPlacesBySection[sectionType]}
          />
          )}
          {webinarId && sectionType === CreateWebinarFormSectionType.Meta
          && (
          <WebinarFilesInput
            webinarId={webinarId as string}
            webinar={webinar}
            place={CreateWebinarFilesPlaces.Video}
          />
          )}
        </div>
        <div className={styles['content-preview']}>
          <WebinarContenPreview
            type={sectionType}
            {...formProps}
          />
        </div>
      </div>
    </section>
  );
}