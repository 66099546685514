import React, { Dispatch, SetStateAction } from 'react';
import Modal from '@/components/Common/Modal';
import Content from '@/components/Common/Modal/Content';
import { ActionKey, UnitActionsKeysByOperationType } from '../useBUnitGroupAction';
import useNLS from '@/components/App/Admin/hooks/hooks';
import BUnitsMenuBtn from '../../BUnitsBlueBtn/BUnitsMenuBtn';
import styles from './BUnitConfirmActionModal.module.sass';
import { useAdminDispatch, useAdminSelector } from '@/store/hooks/adminRedux';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import { makeUnitsGroupOperations } from '@/store/actions/admin/superset/unitsTree';

interface BUnitConfirmActionModalProps {
  isOpen: boolean;
  toggleOpen: Dispatch<SetStateAction<boolean>>;
  operationType: ActionKey;
}

export default function BUnitConfirmActionModal({
  isOpen,
  toggleOpen,
  operationType,
}: BUnitConfirmActionModalProps) {
  const tNLS = useNLS();
  const dispatch = useAdminDispatch();
  const { supersetId } = useAdminSelector(selectSuperset);

  const confirmAction = () => {
    dispatch(
      makeUnitsGroupOperations({
        supersetId,
        operationType,
      }),
    );
    toggleOpen(false);
  };

  return (
    <Modal
      isShowing={isOpen}
      hide={() => toggleOpen(false)}
      title={tNLS(`${UnitActionsKeysByOperationType[operationType]}.confirm`)}
    >
      <Content>
        <div className={styles.buttons}>
          <BUnitsMenuBtn
            type="reject"
            handleButtonClick={() => toggleOpen(false)}
          >
            {tNLS('mail.tmpl.cancel')}
          </BUnitsMenuBtn>
          <BUnitsMenuBtn
            handleButtonClick={confirmAction}
          >
            {tNLS('tree.select.go')}
          </BUnitsMenuBtn>
        </div>
      </Content>
    </Modal>
  );
}