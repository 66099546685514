import React from 'react';
import styles from './BaseWrapper.module.sass';
import Footer from '@/components/Common/BaseTemplate/Footer';

interface BaseWrapperProps {
  children: React.ReactNode;
  header?: React.ReactNode;
}

function BaseWrapper({
  children,
  header,
}: BaseWrapperProps) {
  const defaultLangId = window.location.host.includes('bssl')
  || window.location.host.includes('azimuth')
    ? 'ru'
    : 'en';

  return (
    <div
      className={`${styles.wrapper} wrapper`}
    >
      {header}
      <main className={styles.main}>{children}</main>
      <Footer langId={defaultLangId} />
    </div>
  );
}

export default BaseWrapper;