import React from 'react';
import styles from './CardSelectDropdownButton.module.sass';

interface CardSelectDropdownButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {

}

export default function CardSelectDropdownButton({ children, ...buttonProps }: CardSelectDropdownButtonProps) {
  return (
    <button
      type="button"
      className={styles['select-btn']}
      {...buttonProps}
    >
      {children}
    </button>
  );
}