import React, {
  ChangeEvent,
  ReactNode, useRef,
  useState,
} from 'react';
import styles from './UploadingInputWithGallerey.module.sass';
import FilesGallerey from '../../../../../FilesForUploadList/FilesForUploadList';
import FilesDragNDropContainer from './FilesDragNDrop';
import UploadingInputLabel from './UploadingInputLabel/UploadingInputLabel';

interface UploadingInputProps {
  acceptFilesExt?: string[];
  title?: string | ReactNode
  uploadedFiles: string[];
  uploadFiles: (files: File[]) => void;
  id: string;
}

export default function UploadingInputWithGallerey({
  acceptFilesExt,
  title,
  uploadedFiles,
  uploadFiles,
  id,
}: UploadingInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [newFiles, setNewFiles] = useState<File[]>([]);

  const addFiles = (files: FileList) => {
    const addedFiles = Array.from(files)
      .filter((file) => {
        if (!acceptFilesExt) return true;
        return acceptFilesExt.includes(file.type);
      });
    setNewFiles(addedFiles);
    uploadFiles(addedFiles);
  };

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (!files) return;
    addFiles(files);
  };

  return (
    <FilesDragNDropContainer
      className={styles['upload-drop-container']}
      onFilesAdded={addFiles}
    >
      <FilesGallerey
        uploadedFilesSrc={uploadedFiles}
        newFiles={newFiles}
      />
      <input
        ref={inputRef}
        className={styles['files-hidden-input']}
        type="file"
        multiple
        onChange={onFileChange}
        id={id}
        {...(acceptFilesExt ? { accept: acceptFilesExt.join(',') } : {})}
      />
      <UploadingInputLabel
        text={title}
        htmlFor={id}
      />
    </FilesDragNDropContainer>
  );
}