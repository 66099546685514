import React from 'react';
import styles from './SuperAdminUserMetaInfo.module.sass';
import SuperAdminUserInfoItem from './SuperAdminUserInfoItem';
import { AdminUserInfoItems } from './consts/AdminUserItemConsts';
import { User } from '../../../../types/usersList';

interface SuperAdminUserMetaInfoProps {
  user: User
}

export default function SuperAdminUserMetaInfo({ user }: SuperAdminUserMetaInfoProps) {
  return (
    <div className={styles['meta-info']}>
      {AdminUserInfoItems.map((key) => (
        <SuperAdminUserInfoItem
          key={`${user.id}-${key}`}
          user={user}
          fieldKey={key}
        />
      ))}
    </div>
  );
}