import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import WebinarInputContainer from '../../../WebinarInputContainer/WebinarInputContainer';
import {
  FieldTitleByName,
  WebinarInputTypeByName,
} from '../../consts/createWebinarPageConsts';
import {
  CreateWebinarFieldsType,
  CreateWebinarFielsNamesEnum,
} from '../../types/webinarPageTypes';
import styles from './CreateWebinarFormField.module.sass';
import { getClearClassNames } from '@/helpers/helpers';
import CreateWebinarField from './CreateWebinarField/CreateWebinarField';

interface CreateWebinarFormFieldProps extends UseFormReturn<CreateWebinarFieldsType> {
  name: CreateWebinarFielsNamesEnum;
}

export default function CreateWebinarFormField({ name, ...formProps }: CreateWebinarFormFieldProps) {
  return (
    <WebinarInputContainer
      title={FieldTitleByName[name]}
      id={`webinar-${name}`}
      additionalClassNames={getClearClassNames([
        styles[WebinarInputTypeByName[name]],
        (name === CreateWebinarFielsNamesEnum.Description || name === CreateWebinarFielsNamesEnum.RegisterText) && styles['text-area'],
      ])}
      labelClassNames={
        getClearClassNames([WebinarInputTypeByName[name] === 'checkbox' && styles['label-check']])
      }
    >
      <CreateWebinarField
        name={name}
        {...formProps}
      />
    </WebinarInputContainer>
  );
}