import React from 'react';
import { User } from '../../types/usersList';
import styles from './SuperAdminUser.module.sass';
import SuperAdminUserForm from './SuperAdminUserForm/SuperAdminUserForm';
import SuperAdminUserSummary from './SuperAdminUserSummary/SuperAdminUserSummary';
import { getClearClassNames } from '@/helpers/helpers';

interface SuperAdminUserProps {
  user: User;
}

export default function SuperAdminUser({ user }: SuperAdminUserProps) {
  const { isValid } = user;
  return (
    <details
      className={getClearClassNames([
        styles.user,
        !isValid && styles.invalid,
      ])}
    >
      <SuperAdminUserSummary user={user} />
      <SuperAdminUserForm user={user} />
    </details>
  );
}