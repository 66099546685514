import React from 'react';
import { Tooltip } from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import styles from './ScoreTypeToggler.module.sass';
import { getClearClassNames } from '@/helpers/helpers';
import { selectScoreType } from '@/store/selectors/admin/superset/process/cuts/fourD';
import { PERCENTILE_SCORE_TYPE } from '@/components/App/Admin/Results/Cuts/FourD/constants';
import { setScoreType } from '@/store/actions/admin/superset/process/cuts/fourD';
import useNLS from "@/components/App/Admin/hooks/hooks";

interface DataScoreTypeToggler {
  externalClasses: {
    wrapScoreTypeToggler: string,
  }
}

function ScoreTypeToggler({ externalClasses }: DataScoreTypeToggler) {
  const dispatch = useDispatch();
  const scoreType = useSelector(selectScoreType);
  const tNLS = useNLS();

  const toggleScoreType = () => {
    dispatch(setScoreType());
  };

  return (
    <div className={getClearClassNames([
      styles['wrap-score-type-toggler'],
      externalClasses.wrapScoreTypeToggler,
    ])}
    >
      <Tooltip id="btnScoreTypeToggler" />
      <button
        type="button"
        className={getClearClassNames([
          'btn-without-style',
          styles['btn-score-type-toggler'],
          scoreType === PERCENTILE_SCORE_TYPE
            ? styles['percentile-type']
            : styles['absolute-type'],
        ])}
        data-tooltip-id="btnScoreTypeToggler"
        data-tooltip-content={
            scoreType === PERCENTILE_SCORE_TYPE
              ? tNLS('results.4d.percentile')
              : tNLS('results.4d.absolute')
              }
        onClick={toggleScoreType}
      >
        {' '}
        <span
          className={`icon-type-factor-sctruct ${styles['icon-type-factor-sctruct']}`}
        />
      </button>
    </div>
  );
}

export default ScoreTypeToggler;