import React from 'react';
import Modal from '@/components/Common/Modal';
import Content from '@/components/Common/Modal/Content';
import styles from './SelectFileModal.module.sass';
import FilesGallerey from '@/components/App/Admin/SuperAdmin/SuperUploading/FilesForUploadList/FilesForUploadList';

interface SelectFileModalProps {
  isOpen: boolean;
  closeModal: () => void;
  filesSrc: string[];
  selectFile: (src: string) => void
}
export default function SelectFileModal({
  isOpen, closeModal, filesSrc, selectFile,
}: SelectFileModalProps) {
  return (
    <Modal
      isShowing={isOpen}
      hide={closeModal}
      title="Выберите картинку"
      externalClassNames={{
        contentClass: styles.content,
      }}
    >
      <Content>
        <FilesGallerey
          fileHandleClick={selectFile}
          uploadedFilesSrc={filesSrc}
        />
      </Content>
    </Modal>
  );
}