import React from 'react';
import styles from './SuperAdminUserSummary.module.sass';
import { User } from '../../../types/usersList';
import SuperAdminUserMetaInfo from './SuperAdmnUsetMetaInfo/SuperAdminUserMetaInfo';
import SuperAdminUserMainInfo from './SuperAdminUserMainInfo/SuperAdminUserMainInfo';
import { getClearClassNames } from '@/helpers/helpers';

interface SuperAdminUserSummaryProps {
  user: User;
}

export default function SuperAdminUserSummary({
  user,
}: SuperAdminUserSummaryProps) {
  const { isValid } = user;
  return (
    <summary
      className={
      getClearClassNames([styles.summary, !isValid && styles.invalid])
      }
    >
      {!isValid && (
      <div className={styles['invalid-banner']}>
        Почта не подтверждена
      </div>
      )}
      <SuperAdminUserMainInfo user={user} />
      <SuperAdminUserMetaInfo user={user} />
    </summary>
  );
}