// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".main-info--QZ4TS{display:flex;flex-direction:column;gap:0.625rem;align-items:baseline}.main-info--QZ4TS .name--mtECn{color:var(--main-color);font-size:1.25rem}.main-info--QZ4TS .email--2uy-Q{color:var(--secondary-color);font-size:0.875rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/SuperAdmin/UsersListPage/UsersList/SuperAdminUser/SuperAdminUserSummary/SuperAdminUserMainInfo/SuperAdminUserMainInfo.module.sass"],"names":[],"mappings":"AAEA,kBACE,YAAA,CACA,qBAAA,CACA,YAAA,CACA,oBAAA,CACA,+BACE,uBAAA,CACA,iBAAA,CACF,gCACE,4BAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main-info": "main-info--QZ4TS",
	"name": "name--mtECn",
	"email": "email--2uy-Q"
};
export default ___CSS_LOADER_EXPORT___;
