import React from 'react';
import { useDispatch } from 'react-redux';
import styles from '@/components/Common/NotificationsBar/NotificationContainer/NotificationContainer.module.sass';
import { removeNotification } from '@/store/reducers/notify';

interface RemoveNotificationButtonProps {
  isHidden: boolean;
  index: number;
  notifyId: string;
  withCloseBtn: boolean;
}

function RemoveNotificationButton({
  isHidden, index, notifyId, withCloseBtn = true,
}: RemoveNotificationButtonProps) {
  const dispatch = useDispatch();

  if (!withCloseBtn) return null;

  if (isHidden && index !== 0) return null;

  return (
    <button
      type="button"
      className={`${styles['close-button']} btn-without-style`}
      onClick={(e) => {
        e.stopPropagation();
        dispatch(removeNotification(notifyId));
      }}
    >
      {' '}
      <span className={`${styles.icon} icon-remove-light`} />
    </button>
  );
}

export default RemoveNotificationButton;