import { createSlice } from '@reduxjs/toolkit';
import LoadingStatus from '@/constants/enums';
import { AdminsListInitialState } from '../types/usersList';
import { getUsersList } from '../actions/usersList';

const initialState: AdminsListInitialState = {
  usersList: {},
  loadingStatus: LoadingStatus.Idle,
};

export const UsersListSlice = createSlice({
  name: 'adminList',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsersList.pending, (state) => {
        state.loadingStatus = LoadingStatus.Loading;
      })
      .addCase(getUsersList.fulfilled, (state, { payload }) => {
        state.usersList = payload.reduce((acc, user) => ({ ...acc, [user.id]: user }), {});
        state.loadingStatus = LoadingStatus.Received;
      })
      .addCase(getUsersList.rejected, (state) => {
        state.loadingStatus = LoadingStatus.Error;
      });
  },
});

const { reducer } = UsersListSlice;

export default reducer;