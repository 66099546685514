import React, { Dispatch, SetStateAction } from 'react';
// import styles from './SelectTargetDepartmentInput.module.sass';

interface SelectTargetDepartmentInputProps {
  inputValue: string;
  setInputValue: Dispatch<SetStateAction<string>>;
  toggleOpen: Dispatch<SetStateAction<boolean>>;
  id: string;
}

export default function SelectTargetDepartmentInput({
  inputValue, setInputValue, toggleOpen, id,
}: SelectTargetDepartmentInputProps) {
  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    toggleOpen(true);
    const input = e.target;
    setInputValue(input.value);
  };

  return (
    <input
      id={id}
      type="text"
      value={inputValue}
      onFocus={() => toggleOpen(true)}
      onChange={handleChangeInput}
    />
  );
}