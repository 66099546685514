import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import Notify from '@/components/Common/Notify';
import styles from '@/components/Common/NotificationsBar/NotificationContainer/NotificationContainer.module.sass';
import useNLS from '@/components/App/Admin/hooks/hooks';
import NotificationTitle
  from '@/components/Common/NotificationsBar/AdminNotification/NotificationTitle/NotificationTitle';
import RemoveNotificationButton
  from '@/components/Common/NotificationsBar/AdminNotification/RemoveNotificationButton/RemoveNotificationButton';
import { removeNotification } from '@/store/reducers/notify';

interface AdminNotificationProps {
  notify: NotifyType
  isHidden: boolean;
  withAnimation: boolean;
  index: number;
  withCloseBtn?: boolean;
}

function AdminNotification({
  notify, isHidden, withAnimation, index, withCloseBtn = true,
}: AdminNotificationProps) {
  const tNLS = useNLS();
  const lifeTimer = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!lifeTimer.current && notify.lifeTime) {
      // @ts-ignore
      lifeTimer.current = setTimeout(
        () => dispatch(removeNotification(notify.id)),
        notify.lifeTime,
      );
    }
    // @ts-ignore
    return () => clearTimeout(lifeTimer.current);
    // eslint-disable-next-line
  }, []);

  return (
    <Notify
      onClose={() => null}
      type={notify.type}
      additionalClassNames={`
        notify ${styles.notify} 
        ${styles['with-close-icon']}
        ${isHidden && index > 2 && styles.hidden}`}
      withAnimation={withAnimation}
    >
      <>
        <RemoveNotificationButton
          isHidden={isHidden}
          index={index}
          notifyId={notify.id}
          withCloseBtn={withCloseBtn}
        />
        <div className={styles['notify-content']}>
          <NotificationTitle
            supersetId={notify.supersetId}
            supersetName={notify.supersetName}
          />
          <span>{tNLS(notify.message) || notify.message}</span>
        </div>
      </>
    </Notify>
  );
}

export default AdminNotification;