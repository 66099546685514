import React from 'react';
import Select, { Props as SelectProps } from 'react-select';
import './BaseSelect.sass';

// Define an interface for the component props
interface BaseSelectProps extends SelectProps {
  id: string;
  label?: string;
}

function BaseSelect({ id, label, ...props }: BaseSelectProps) {
  return (
    <div>
      {label && (
      <label htmlFor={id}>
        {label}
      </label>
      )}
      <Select
        inputId={id}
        className="base-select"
        classNamePrefix="base-select"
        isSearchable={false}
        {...props}
        unstyled
      />
    </div>
  );
}

export default BaseSelect;