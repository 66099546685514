import React from 'react';
import styles from './SuperAdminUserInfoItem.module.sass';
import { User } from '../../../../../types/usersList';
import { AdminUserInfoItemsKeys } from '../types/AdminUserItem';
import { TitleByKey } from '../consts/AdminUserItemConsts';

interface SuperAdminUserInfoItemProps {
  user: User
  fieldKey: AdminUserInfoItemsKeys;
}

export default function SuperAdminUserInfoItem({ user, fieldKey }: SuperAdminUserInfoItemProps) {
  if (!user[fieldKey]) return null;

  return (
    <div className={styles['info-item']}>
      <span>{TitleByKey[fieldKey]}</span>
      <span>{user[fieldKey]}</span>
    </div>
  );
}