// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".preview--KGUcL{width:60%}.webinar-text--3OD4E{max-width:50rem}.webinar-text--3OD4E .webinar-title--E6lzI{margin:0 0 1.25rem 0}.webinar-text--3OD4E .meta-information--t5l3s{font-weight:400;color:var(--secondary-color);font-size:1.25rem}.webinar-text--3OD4E .webinar-description--ICvjK{font-size:1.25rem}.webinar-text--3OD4E .webinar-description--ICvjK img{max-width:100%}.webinar-text--3OD4E .webinar-description--ICvjK ul{padding-left:0.75rem}.webinar-text--3OD4E .webinar-description--ICvjK ul li{display:flex;gap:0.75rem;margin-bottom:0.75rem}.webinar-text--3OD4E .webinar-description--ICvjK p{margin:1.25rem 0}.webinar-text--3OD4E .webinar-description--ICvjK a{color:#2f80ed}", "",{"version":3,"sources":["webpack://./src/components/App/Webinar/WebinarRegisterPage/WebinarTexts/WebinarTexts.module.sass"],"names":[],"mappings":"AAEA,gBACE,SAAA,CAEF,qBACE,eAAA,CACA,2CACE,oBAAA,CACF,8CACE,eAAA,CACA,4BAAA,CACA,iBAAA,CACF,iDACE,iBAAA,CACA,qDACE,cAAA,CACF,oDACE,oBAAA,CACA,uDACE,YAAA,CACA,WAAA,CACA,qBAAA,CACJ,mDACE,gBAAA,CACF,mDACE,aAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"preview": "preview--KGUcL",
	"webinar-text": "webinar-text--3OD4E",
	"webinar-title": "webinar-title--E6lzI",
	"meta-information": "meta-information--t5l3s",
	"webinar-description": "webinar-description--ICvjK"
};
export default ___CSS_LOADER_EXPORT___;
