import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './BtnSwitchLangs.module.sass';
import {
  selectCurrentLangID,
  selectLanguagesMasterSuperset,
} from '@/store/selectors/admin/superset/projects';
import FlagIcon from '@/components/Common/Elements/cssIcons/FlagIcon/FlagIcon';
import BtnDropDown from '@/components/Common/Elements/BtnDropDown';
import { getClearClassNames } from '@/helpers/helpers';
import { setCurrentLanguageID } from '@/store/actions/admin';

function BtnSwitchLangs({ className = null }) {
  const dispatch = useDispatch();
  const langs = useSelector(selectLanguagesMasterSuperset);
  const selectedLangID = useSelector(selectCurrentLangID);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const getRestLangs = () => Object.keys(langs)
    .filter((langID) => langID !== selectedLangID)
    .map((langID) => (
      <li className={styles['item-lang']} key={langID}>
        <button
          type="button"
          className={getClearClassNames([
            'btn-without-style',
            styles['btn-item-lang'],
          ])}
          onClick={() => dispatch(setCurrentLanguageID(langID))}
        >
          <FlagIcon langId={langID} classNames={styles['icon-lang']} />
          {langID.toUpperCase()}
        </button>
      </li>
    ));

  if (selectedLangID === null) return null;
  if (Object.keys(langs).length === 1) return null;

  return (
    <div
      className={getClearClassNames([
        styles['wrap-btn-switch-langs'],
        className,
      ])}
    >
      <BtnDropDown
        classNames={styles['langs-dropdown']}
        isOpen={isDropdownOpen}
        setIsOpen={setIsDropdownOpen}
      >
        <button
          type="button"
          className={getClearClassNames([
            'btn-without-style',
            styles['btn-selected-lang'],
            styles['btn-item-lang'],
            isDropdownOpen && styles.open,
          ])}
          onClick={() => {}}
        >
          <FlagIcon langId={selectedLangID} classNames={styles['icon-lang']} />
          {selectedLangID.toUpperCase()}
        </button>

        <div className={styles['langs-dropdown-content']}>
          <ul className={styles['list-langs']}>{getRestLangs()}</ul>
        </div>
      </BtnDropDown>
    </div>
  );
}

BtnSwitchLangs.propTypes = {
  className: PropTypes.string,
};

export default BtnSwitchLangs;