// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dropdown-container--ui6FR{position:relative;border-radius:0.5rem;background:var(--admin-background-interactive-elements);border:.5px solid var(--secondary-color);margin:0 0.75rem 0 0;padding:0.125rem 0.5rem 0.125rem 1rem;width:100%}.dropdown-container--ui6FR .error--w1qX\\+{position:absolute;top:-16px;left:0;color:var(--error-color);font-size:0.625rem}.dropdown-container--ui6FR input{font-size:1rem;height:100%;width:100%;outline:none}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/CreateBUnitSection/CreateBUnitModal/CreateBUnitForm/SelectTargetDepartment/SelectTargetDepartmentDropdown/SelectTargetDepartmentDropdown.module.sass"],"names":[],"mappings":"AAEA,2BACE,iBAAA,CACA,oBAAA,CACA,uDAAA,CACA,wCAAA,CACA,oBAAA,CACA,qCAAA,CACA,UAAA,CACA,0CACE,iBAAA,CACA,SAAA,CACA,MAAA,CACA,wBAAA,CACA,kBAAA,CACF,iCACE,cAAA,CACA,WAAA,CACA,UAAA,CACA,YAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown-container": "dropdown-container--ui6FR",
	"error": "error--w1qX+"
};
export default ___CSS_LOADER_EXPORT___;
