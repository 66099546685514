import React from 'react';
import styles from './CircleProgress.module.sass';
import Circle, { SvgCircleType } from './SvgCircle/SvgCircle';

interface CircleProgressProps {
  percentage?: number;
}

export default function CircleProgress({ percentage = 0 }: CircleProgressProps) {
  return (
    <svg className={styles['circle-progress']}>
      <g transform={`rotate(-90 ${'100 100'})`}>
        <Circle
          type={SvgCircleType.Background}
        />
        <Circle
          type={SvgCircleType.Fill}
          percentage={percentage}
        />
      </g>
    </svg>
  );
}