import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Tooltip from '@/components/App/Admin/Superset/HeaderSuperset/AssessmentStatus/StatusElement/Tooltip/Tooltip';
import styles from './StatusElement.module.sass';
import ASSESSMENT_STATUS_NLS_BY_KEY from '@/components/App/Admin/Superset/HeaderSuperset/AssessmentStatus/assessmentStatusHelpers';
import useNLS from '@/components/App/Admin/hooks/hooks';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';

function StatusElement({ statusKey, classNames, tooltipClassNames }) {
  const tNLS = useNLS();
  const { assessmentStatus } = useSelector(selectSuperset);
  const getText = (key) => ASSESSMENT_STATUS_NLS_BY_KEY.getText({
    statKey: key,
    getNLS: tNLS,
  });

  const getFormatValue = (key) => ASSESSMENT_STATUS_NLS_BY_KEY.getFormatValue({
    statKey: key,
    values: assessmentStatus,
  });

  return (
    <Tooltip text={getText(statusKey)} tooltipClassNames={tooltipClassNames}>
      <div className={`${classNames} ${styles['status-elements']}`}>
        {getFormatValue(statusKey)}
      </div>
    </Tooltip>
  );
}
StatusElement.propTypes = {
  statusKey: PropTypes.string.isRequired,
  classNames: PropTypes.string,
  tooltipClassNames: PropTypes.string,
};

StatusElement.defaultProps = {
  classNames: '',
  tooltipClassNames: '',
};

export default StatusElement;