import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosProcessing from '@/axiosConfig';

import { User } from '../types/usersList';

// eslint-disable-next-line import/prefer-default-export
export const getUsersList = createAsyncThunk(
  'super/admins',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosProcessing.get<User[]>('/api/admin/super/users');

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);