import React, { Dispatch, SetStateAction } from 'react';
import BUnitEmailModal from '../../BUnitEmailModal/BUnitEmailModal';
import BUnitAddSpecsModal from '../../BUnitAddSpecsModal/BUnitAddSpecsModal';
import BUnitsTransferModal from '../../BUnitTransferModal/BUnitsTransferModal';
import BUnitConfirmActionModal from '../../BUnitConfirmActionModal/BUnitConfirmActionModal';
import { ActionKey } from '../../useBUnitGroupAction';

interface BUnitActionModalProps {
  operationType: ActionKey;
  isShowingModal: boolean;
  toggleModal: Dispatch<SetStateAction<boolean>>;
}

export default function BUnitActionModal({
  operationType, isShowingModal, toggleModal,
}: BUnitActionModalProps) {
  if (!isShowingModal) return null;

  switch (operationType) {
    case 'sendEmail': { return (
      <BUnitEmailModal
        isShowingModal={isShowingModal}
        toggleModal={toggleModal}
      />
    ); }
    case 'transferUnits': { return (
      <BUnitsTransferModal
        isShowingModal={isShowingModal}
        toggleModal={toggleModal}
      />
    ); }
    case 'addSpec': { return (
      <BUnitAddSpecsModal
        isOpen={isShowingModal}
        toggleOpen={() => toggleModal(false)}
      />
    ); }
    default: {
      return (
        <BUnitConfirmActionModal
          isOpen={isShowingModal}
          toggleOpen={toggleModal}
          operationType={operationType}
        />
      );
    }
  }
}