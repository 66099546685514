import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styles from './CreateBUnitForm.module.sass';
import BUnitCardInput, {
  CardFieldType,
} from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardFields/BUnitFieldsContent/BUnitFieldsSections/BUnitCardSection/BUnitCardInput/BUnitCardInput';
import BUnitCardFooterButtons
  from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardFooter/BUnitCardFooterButtons';
import BUnitType from '@/components/App/Admin/BUnitsSection/CreateBUnitSection/CreateBUnitModal/BUnitType/BUnitType';
import { useAdminDispatch, useAdminSelector } from '@/store/hooks/adminRedux';
import { createBUnit } from '@/store/actions/admin/superset/unitsTree';
import { selectUnitsTreeData } from '@/store/selectors/admin/superset/unitsTree';
import BUnitSpecializations from '../../../UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardFields/BUnitFieldsContent/BUnitFieldsSections/BUnitSpecializations';
import SelectTargetDepartment from './SelectTargetDepartment/SelectTargetDepartment';
import { createPathByParentId } from '@/helpers/helpers';

interface CreateUnitFields {
  isDepartment?: boolean;
  name: string;
  email?: string;
  jobTitle?: string;
}

interface CreateBUnitFormProps {
  closeModal: () => void;
  targetDepartmentId?: string;
}

function CreateBUnitForm({ closeModal, targetDepartmentId = undefined }: CreateBUnitFormProps) {
  const [isActor, toggleActor] = useState(true);
  const [newSpecs, setNewSpecs] = useState<string[]>([]);

  const dispatch = useAdminDispatch();
  const { units } = useAdminSelector(selectUnitsTreeData);

  const [targetDepartment, setTargetDepartment] = useState<string | undefined>(targetDepartmentId);

  const handleSelectDepartment = (depId: string) => {
    setTargetDepartment(depId);
  };

  const {
    register, reset, handleSubmit,
  } = useForm<CreateUnitFields>();

  const createUnit = (newUnitFields: CreateUnitFields) => {
    const unitData = isActor
      ? { ...newUnitFields, isActor }
      : { name: newUnitFields.name, isActor };
    dispatch(
      createBUnit({
        unitData: {
          ...unitData,
          specs: newSpecs,
          departments: createPathByParentId(targetDepartment, units),
        },
        targetDepartmentId: targetDepartment,
        isLocal: false,
      }),
    )
      .then(() => {
        closeModal();
        reset();
      }, () => {
        reset();
      });
  };

  const onHandleSubmit = (data: CreateUnitFields) => {
    createUnit(data);
  };

  return (
    <>
      <BUnitType
        isActor={isActor}
        toggleActor={toggleActor}
      />
      <form
        className={styles['create-unit-form']}
        onSubmit={handleSubmit(onHandleSubmit)}
        id="create-unit-form"
        noValidate
      >
        <BUnitCardInput register={register} cardFieldType={CardFieldType.Name} />
        {isActor && (
        <>
          <BUnitCardInput
            register={register}
            cardFieldType={CardFieldType.Email}
          />
          <BUnitCardInput
            register={register}
            cardFieldType={CardFieldType.JobTitle}
          />
          <SelectTargetDepartment
            currentDepartment={targetDepartmentId}
            selectDepartment={handleSelectDepartment}
          />
          <BUnitSpecializations
            spec={newSpecs}
            updateUnitField={setNewSpecs}
          />
        </>
        )}
        <BUnitCardFooterButtons isUnitUpdated reset={reset} formId="create-unit-form" />
      </form>
    </>
  );
}

export default CreateBUnitForm;