export enum CreateWebinarFielsNamesEnum {
  WebinarKey = 'webinarKey',
  IsActive = 'isActive',
  Date = 'startTime',
  WebinarName = 'title',
  Location = 'location.place',
  SpeackerName = 'speaker.speackerName',
  SpeackerInfo = 'speaker.speackerInfo',
  Title = 'description.title',
  Description = 'description.description',
  RegisterText = 'registerText',
  ShowLanding = 'showLanding',
}

export enum CreateWebinarFormSectionType {
  Meta = 'meta',
  Content = 'content',
  Speaker = 'speaker',
}

export enum CreateWebinarFilesPlaces {
  Banner = 'banner',
  Video = 'video',
  Logo = 'logo',
  Speaker = 'speaker',
}

export interface EditWebinarArgs {
  webinarFields: CreateWebinarFieldsType;
  oldWebinarId?: string;
}
export interface CreateWebinarFieldsType {
  [CreateWebinarFielsNamesEnum.WebinarKey]: string;
  [CreateWebinarFielsNamesEnum.IsActive]: boolean;
  [CreateWebinarFielsNamesEnum.ShowLanding]: boolean;
  [CreateWebinarFielsNamesEnum.Date]: string;
  [CreateWebinarFielsNamesEnum.WebinarName]: string;
  [CreateWebinarFielsNamesEnum.Location]: string;
  [CreateWebinarFielsNamesEnum.SpeackerName]: string;
  [CreateWebinarFielsNamesEnum.SpeackerInfo]: string;
  [CreateWebinarFielsNamesEnum.Title]: string;
  [CreateWebinarFielsNamesEnum.Description]: string;
  [CreateWebinarFielsNamesEnum.RegisterText]: string;
  files?: any
}